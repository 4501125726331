import { UNCATEGORIZED } from "./constants";

export const getPlaidDetailedString = (mainFriendlyName, subFriendlyName) => {
  return `${getPlaidPrimaryString(mainFriendlyName)}_${getPlaidPrimaryString(subFriendlyName)}`;
};

export const getPlaidPrimaryString = (mainFriendlyName) => {
  let plaidPrimaryString = mainFriendlyName;
  plaidPrimaryString = plaidPrimaryString.replace(/\(.*?\)/g, "");
  plaidPrimaryString = plaidPrimaryString.toUpperCase();
  plaidPrimaryString = plaidPrimaryString.replace("&", "AND");
  plaidPrimaryString = plaidPrimaryString.replace(/[^A-Z0-9\s]/g, "").trim();
  plaidPrimaryString = plaidPrimaryString.replace(/\s+/g, "_");
  return plaidPrimaryString;
};

export const getAccount = (accountId, items) => {
  for (const item of items) {
    if (item.accounts) {
      for (const account of item.accounts) {
        if (account.account_id === accountId) {
          return account;
        }
      }
    }
  }
  return null;
};

export const getTransactionCategory = (pkToCategoryMap, mainFriendlyNameToCategoryMap, transaction) => {
  let category = pkToCategoryMap?.get(JSON.stringify(transaction.appCategory));
  if (!category) {
    category = mainFriendlyNameToCategoryMap?.get(UNCATEGORIZED)[0];
  }
  return category;
};
