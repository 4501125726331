import { Alert, Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { forgotPassword } from "../../api/auth/cognito";
import finwatch_logo from "../../assets/finwatch_app_icon_white_border.png";
import { APP_PATHS } from "../../utils/constants";

export const ForgotPassword = (props) => {
  const location = useLocation();
  const history = useHistory();
  // Page State
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [submitError, setSubmitError] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.email]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = re.test(String(email).toLowerCase());
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: isValid ? "" : "Invalid email address.",
    }));
    return isValid;
  };

  const handleSubmitClicked = (event) => {
    event.preventDefault();
    setIsSubmitLoading(true);
    forgotPassword({
      email: email,
    })
      .then((response) => {
        if (response.$metadata.httpStatusCode !== 200) {
          throw Error("Unexpected error");
        }

        history.push(APP_PATHS.CONFIRM_FORGOT_PASSWORD, { email: email });
      })
      .catch((e) => {
        console.error(e);
        setSubmitError("Unexpected error.");
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  const handleBackClicked = () => {
    history.goBack();
  };

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Stack
        direction="column"
        sx={{
          margin: "auto",
          justifyContent: "center",
          height: "100vh",
          width: "300px",
          maxWidth: "90vw",
          color: "secondary.main",
        }}
      >
        <Box
          component="img"
          sx={{
            margin: "10px auto",
            width: props.isDesktopScreenSize ? "130px" : "75px",
          }}
          alt="Logo"
          src={finwatch_logo}
        />
        <Typography variant="h3" sx={{ fontFamily: "Noto Serif Balinese" }} textAlign="center">
          finwatch
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: "Noto Serif Balinese" }} textAlign="center">
          Forgot your password?
          <br /> Please enter your email below.
        </Typography>
        <form onSubmit={handleSubmitClicked}>
          <TextField
            name="email"
            label="Email"
            fullWidth
            variant="filled"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onBlur={() => validateEmail(email)}
            error={!!errors.email}
            helperText={errors.email}
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
              backgroundColor: "secondary.main",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          />
          {submitError !== "" && (
            <Alert severity="error" variant="filled" sx={{ marginTop: "10px", marginBottom: "20px" }}>
              {submitError}
            </Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            sx={{
              display: "flex",
              margin: "auto",
              marginTop: "10px",
              height: "50px",
              backgroundColor: "primary.dark",
            }}
            disabled={isSubmitLoading}
          >
            {isSubmitLoading ? <CircularProgress /> : "Submit"}
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={handleBackClicked}
            sx={{
              color: "primary.main",
              backgroundColor: "secondary.main",
              ":hover": {
                color: "secondary.main",
                backgroundColor: "secondary.dark",
              },
              display: "flex",
              margin: "auto",
              marginTop: "10px",
              marginBottom: "10px",
              height: "50px",
            }}
          >
            Back
          </Button>
        </form>
      </Stack>
    </Box>
  );
};

ForgotPassword.propTypes = {
  isDesktopScreenSize: PropTypes.bool.isRequired,
};

export default ForgotPassword;
