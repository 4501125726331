import CloseIcon from "@mui/icons-material/Close";
import { Alert, CircularProgress, IconButton, Snackbar, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { getItemsByUser, syncUserData } from "../api/budget";
import { useAuth } from "./auth/AuthProvider";

const SyncSnackbar = (props) => {
  const { accessToken, userId } = useAuth();
  // App State
  const [isSyncLoading, setIsSyncLoading] = useState(false);
  const [syncWarningMessage, setSyncWarningMessage] = useState("");
  const [syncErrorMessage, setSyncErrorMessage] = useState("");
  const [isSyncSnackbarOpen, setIsSyncSnackbarOpen] = useState(false);

  const erroneousAccountsRef = useRef([]);

  useEffect(() => {
    if (props.lastAccountBalanceSync) {
      if (props.doesAccountHaveItems && !isLastAccountBalanceSyncToday(props.lastAccountBalanceSync)) {
        setIsSyncLoading(true);
        setIsSyncSnackbarOpen(true);
        syncUserData(userId, accessToken)
          .then((response) => {
            if (response.error) {
              setSyncErrorMessage(response.error);
            } else {
              checkIfSyncIsComplete(false, 0, accessToken);
            }
          })
          .catch((e) => {
            setSyncErrorMessage("Unexpected Error.");
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lastAccountBalanceSync]);

  useEffect(() => {
    if (props.isNewItemAdded) {
      setIsSyncLoading(true);
      setIsSyncSnackbarOpen(true);
      checkIfSyncIsComplete(false, 0, accessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isNewItemAdded]);

  const isLastAccountBalanceSyncToday = (lastAccountBalanceSync) => {
    const today = new Date().toISOString().substring(0, 10);
    return lastAccountBalanceSync === today;
  };

  const checkIfSyncIsComplete = (isSyncComplete, retryCount, token) => {
    const MAX_RETRIES = 40;
    setTimeout(async () => {
      erroneousAccountsRef.current = [];
      const response = await getItemsByUser(userId, token);
      isSyncComplete = true;
      for (const item of response.items) {
        if (item.isSyncing) {
          isSyncComplete = false;
          break;
        }
        if (item.isError) {
          erroneousAccountsRef.current.push(item);
        }
      }
      if (retryCount > MAX_RETRIES) {
        setSyncErrorMessage("Unexpected Error.");
      } else if (!isSyncComplete) {
        checkIfSyncIsComplete(isSyncComplete, ++retryCount, token);
      } else {
        if (erroneousAccountsRef.current.length > 0) {
          setSyncWarningMessage(
            `${erroneousAccountsRef.current.length} ${
              erroneousAccountsRef.current.length === 1 ? " account needs attention." : " accounts need attention."
            } Please review your "My Accounts" section for errors.`
          );
        } else {
          // Success!
          props.fetchItemsByUser(userId, token);
        }
        props.setIsNewItemAdded(false);
        setIsSyncLoading(false);
      }
    }, 3000);
  };

  const handleCloseSyncSnackbar = () => {
    setIsSyncSnackbarOpen(false);
  };

  return (
    <>
      {isSyncLoading ? (
        <Snackbar open={isSyncSnackbarOpen}>
          <Alert icon={false} variant="filled" sx={{ width: "100%", backgroundColor: "primary.light" }}>
            <Stack direction="row">
              <CircularProgress size="18px" sx={{ color: "white", marginRight: "10px", marginTop: "2px" }} />
              <Typography>We are syncing your accounts...</Typography>
            </Stack>
          </Alert>
        </Snackbar>
      ) : syncErrorMessage !== "" ? (
        <Snackbar open={isSyncSnackbarOpen}>
          <Alert icon={false} variant="filled" sx={{ width: "100%", backgroundColor: "error.main" }}>
            <Stack direction="row">
              <Typography>{syncErrorMessage}</Typography>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSyncSnackbar}
                sx={{ height: "25px", width: "25px", marginLeft: "10px" }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Stack>
          </Alert>
        </Snackbar>
      ) : syncWarningMessage !== "" ? (
        <Snackbar open={isSyncSnackbarOpen}>
          <Alert icon={false} variant="filled" sx={{ width: "100%", backgroundColor: "warning.main" }}>
            <Stack direction="row">
              <Typography>{syncWarningMessage}</Typography>
            </Stack>
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar open={isSyncSnackbarOpen} autoHideDuration={10000}>
          <Alert icon={false} variant="filled" sx={{ width: "100%", backgroundColor: "success.main" }}>
            <Stack direction="row">
              <Typography>Accounts synced successfully!</Typography>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSyncSnackbar}
                sx={{ height: "25px", width: "25px", marginLeft: "10px" }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Stack>
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

SyncSnackbar.propTypes = {
  lastAccountBalanceSync: PropTypes.string,
  fetchItemsByUser: PropTypes.func.isRequired,
  doesAccountHaveItems: PropTypes.bool.isRequired,
  isNewItemAdded: PropTypes.bool.isRequired,
  setIsNewItemAdded: PropTypes.func.isRequired,
};

export default SyncSnackbar;
