import DeleteIcon from "@mui/icons-material/Delete";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { removeItem, syncAccountBalance } from "../api/budget";
import { getUpdateLinkToken } from "../api/plaid";
import { formatIntToMoney } from "../utils/customFormats";
import { getInstitutionBankName, getInstitutionLogo } from "../utils/institutionLogoMapper";
import { useAuth } from "./auth/AuthProvider";

const Account = (props) => {
  const { accessToken, userId } = useAuth();
  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState(null);
  const isSettingsMenuOpened = Boolean(settingsMenuAnchorEl);

  // Plaid State
  const [linkToken, setLinkToken] = useState(null);
  const config = {
    token: linkToken,
    onSuccess: (publicToken, metadata) => {
      console.log(`onSuccess(${publicToken}, %j)`, metadata);
      syncAccountBalance(userId, props.account.sk, accessToken);
    },
    onExit: (error, metadata) => {
      console.log(`onExit(${error}, ${metadata})`);
    },
    onEvent: (eventName, metadata) => {
      console.log(`onEvent(${eventName}, ${metadata})`);
    },
  };
  const { open, ready } = usePlaidLink(config);

  const handleSettingsMenuOpened = (event) => {
    setSettingsMenuAnchorEl(event.currentTarget);
    getUpdateLinkToken(userId, props.account.sk, accessToken).then((response) => {
      setLinkToken(response.linkToken);
    });
  };

  const handleSettingsMenuClosed = () => {
    setSettingsMenuAnchorEl(null);
  };

  const handleReconnectClicked = () => {
    open();
  };

  const handleRemoveClicked = () => {
    removeItem(userId, props.account.sk, accessToken).then(() => {
      props.fetchItemsByUser(userId, accessToken);
    });
    handleSettingsMenuClosed();
  };

  return (
    <Paper
      elevation={1}
      sx={{
        margin: "5px",
        padding: "10px",
        color: "secondary.main",
        backgroundColor: "primary.main",
        borderRadius: "10px",
      }}
    >
      <Stack direction="row">
        <Avatar
          alt="account-logo"
          src={getInstitutionLogo(props.account.institutionId)}
          sx={{
            height: props.isDesktopScreenSize ? "50px" : "25px",
            width: props.isDesktopScreenSize ? "50px" : "25px",
            border: props.isDesktopScreenSize ? "5px solid white" : "2px solid white",
            marginRight: "10px",
          }}
        />
        {props.account.isNoAccountItem ? (
          <Box>
            {getInstitutionBankName(props.account.institutionId) && (
              <Typography variant={props.isDesktopScreenSize ? "body1" : "body2"}>
                {`${getInstitutionBankName(props.account.institutionId)}`}
              </Typography>
            )}
          </Box>
        ) : (
          <Box>
            <Typography variant={props.isDesktopScreenSize ? "body1" : "body2"}>
              {`${
                getInstitutionBankName(props.account.institutionId)
                  ? `${getInstitutionBankName(props.account.institutionId)} -`
                  : ""
              } ${props.account.name} ${props.account.mask ? `(${props.account.mask})` : ``}`}
            </Typography>
            <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
              {!!props.isPrivacyFilterOn ? "**********" : formatIntToMoney(props.account.balances.current)}
              {props.account.isError && (
                <Tooltip title="An error occurred when syncing your account balance, please try again later">
                  <ErrorOutlineIcon sx={{ color: "error.main", marginLeft: "5px" }} />
                </Tooltip>
              )}
            </Typography>
          </Box>
        )}
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
          <IconButton
            id="account-settings-button"
            aria-controls={isSettingsMenuOpened ? "settings-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={isSettingsMenuOpened ? "true" : undefined}
            onClick={handleSettingsMenuOpened}
            color="secondary"
          >
            <SettingsIcon />
          </IconButton>
          <Menu
            id="account-settings-button"
            anchorEl={settingsMenuAnchorEl}
            open={isSettingsMenuOpened}
            onClose={handleSettingsMenuClosed}
            MenuListProps={{
              "aria-labelledby": "account-settings-button",
            }}
          >
            <MenuItem onClick={handleReconnectClicked} disabled={!ready}>
              <ListItemIcon>
                <SyncIcon />
              </ListItemIcon>
              <ListItemText>Reconnect</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleRemoveClicked}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Remove</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Stack>
    </Paper>
  );
};

Account.propTypes = {
  isDesktopScreenSize: PropTypes.bool.isRequired,
  account: PropTypes.object.isRequired,
  fetchItemsByUser: PropTypes.func.isRequired,
  isPrivacyFilterOn: PropTypes.bool.isRequired,
};

export default Account;
