import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Chip, CircularProgress, IconButton, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { deleteCategory, updateCategory } from "../../api/category";
import { getPlaidDetailedString } from "../../utils/common";
import { useAuth } from "../auth/AuthProvider";
import { useCategory } from "./CategoryProvider";

const CategoryChip = (props) => {
  const { accessToken, userId } = useAuth();
  const { fetchCategoriesByUser } = useCategory();

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [name, setName] = useState(props.name);
  const [nameInputWidth, setNameInputWidth] = useState(0);

  const FONT_SIZE = 10;
  const DEFAULT_INPUT_WIDTH = 120;
  const INPUT_ADORNMENT = 68;
  useEffect(() => {
    if (name.length * FONT_SIZE > DEFAULT_INPUT_WIDTH) {
      setNameInputWidth((name.length + 1) * FONT_SIZE + INPUT_ADORNMENT);
    } else {
      setNameInputWidth(DEFAULT_INPUT_WIDTH + INPUT_ADORNMENT);
    }
  }, [name]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleCategoryUpdate();
    }
  };

  const handleCategoryUpdate = () => {
    setIsUpdateLoading(true);
    const detailed = getPlaidDetailedString(props.category.primary, name);
    const updatedItems = {
      subFriendlyName: name,
      detailed: detailed,
      gsi: detailed,
    };

    updateCategory(userId, props.category.sk, updatedItems, accessToken).then(() => {
      fetchCategoriesByUser();
    });
  };

  const handleCategoryDelete = () => {
    setIsDeleteLoading(true);
    deleteCategory(userId, props.category.sk, accessToken).then(() => {
      fetchCategoriesByUser();
    });
  };

  return isEditing ? (
    <TextField
      inputRef={(input) => input?.focus()}
      variant="outlined"
      value={name}
      onChange={(event) => setName(event.target.value)}
      onKeyDown={handleKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setIsEditing(!isEditing);
              }}
              size="small"
              disabled={isUpdateLoading}
            >
              <CloseIcon />
            </IconButton>
            <IconButton onClick={handleCategoryUpdate} size="small">
              {isUpdateLoading ? <CircularProgress size={18} /> : <CheckIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{ width: `${nameInputWidth}px` }}
    />
  ) : (
    <Chip
      label={props.name}
      color={props.color}
      onClick={() => {
        setIsEditing(!isEditing);
      }}
      onDelete={props.isDeleteAvailable ? handleCategoryDelete : undefined}
      deleteIcon={isDeleteLoading ? <CircularProgress size={18} /> : undefined}
      sx={props.sx}
    />
  );
};

CategoryChip.propTypes = {
  category: PropTypes.object,
  isDeleteAvailable: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  sx: PropTypes.object,
  color: PropTypes.string,
};

export default CategoryChip;
