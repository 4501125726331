export const addCategory = async (userId, mainFriendlyName, subFriendlyName, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/addCategory/${encodeURIComponent(
    userId
  )}/${encodeURIComponent(mainFriendlyName)}/${encodeURIComponent(subFriendlyName)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    method: "POST",
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const updateCategory = async (userId, categoryId, items, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/updateCategory/${encodeURIComponent(
    userId
  )}/${categoryId}/${encodeURIComponent(JSON.stringify(items))}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    method: "POST",
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`POST ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const deleteCategory = async (userId, categoryId, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/deleteCategory/${encodeURIComponent(
    userId
  )}/${encodeURIComponent(categoryId)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    method: "DELETE",
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const resetUserCategories = async (userId, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/resetUserCategories/${encodeURIComponent(userId)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    method: "POST",
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};
