import CottageIcon from "@mui/icons-material/Cottage";
import MenuIcon from "@mui/icons-material/Menu";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  AppBar,
  Avatar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logOut } from "../api/auth/auth";
import finwatch_logo from "../assets/finwatch_app_icon_white_border.png";
import { useAuth } from "./auth/AuthProvider";
import { useWebSocket } from "./websocket/WebSocketProvider";
import { APP_PATHS } from "../utils/constants";

const NavBar = (props) => {
  const { clearSession } = useAuth();

  const history = useHistory();
  const { closeWebsocketConnection } = useWebSocket();
  const [anchorElUserMenu, setAnchorElUserMenu] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUserMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUserMenu(null);
  };

  const logoutWithRedirect = () => {
    logOut().then((response) => {
      console.log(response);
      closeWebsocketConnection();
      clearSession();
      history.push(APP_PATHS.ROOT);
    });
  };

  const renderDrawer = () => {
    return (
      <Drawer
        anchor={props.isDesktopScreenSize ? "left" : "top"}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box sx={{ width: 250, padding: `env(safe-area-inset-top) 0 0 env(safe-area-inset-left)` }} role="presentation">
          <List>
            <RouterNavLink to="/home" style={{ textDecoration: "none", color: "inherit" }}>
              <ListItem key="Home" disablePadding>
                <ListItemButton onClick={() => setIsDrawerOpen(false)}>
                  <ListItemIcon>{<CottageIcon />}</ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </ListItem>
            </RouterNavLink>
            <RouterNavLink to="/trends" style={{ textDecoration: "none", color: "inherit" }}>
              <ListItem key="Trends" disablePadding>
                <ListItemButton onClick={() => setIsDrawerOpen(false)}>
                  <ListItemIcon>{<TrendingUpIcon />}</ListItemIcon>
                  <ListItemText primary="Trends" />
                </ListItemButton>
              </ListItem>
            </RouterNavLink>
          </List>
        </Box>
      </Drawer>
    );
  };

  return (
    <AppBar position="fixed" sx={{ padding: `env(safe-area-inset-top) 0 0 env(safe-area-inset-left)` }}>
      <Toolbar>
        <IconButton onClick={() => setIsDrawerOpen(true)} sx={{ color: "secondary.main", marginRight: "0.5rem" }}>
          <MenuIcon />
        </IconButton>
        <RouterNavLink
          to="/home"
          style={{ textDecoration: "none", color: "inherit", display: "flex", alignItems: "center" }}
        >
          <Box
            component="img"
            sx={{
              height: "40px",
              paddingRight: "10px",
            }}
            alt="Logo"
            src={finwatch_logo}
          />
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "secondary.main",
              fontFamily: "Noto Serif Balinese",
              paddingTop: "8px",
            }}
          >
            finwatch
          </Typography>
        </RouterNavLink>
        <Fragment>
          <Stack direction="row" sx={{ flexGrow: 1 }}></Stack>
          <Stack direction="row">
            {props.isDesktopScreenSize && (
              <Typography sx={{ marginRight: "15px", display: "flex", alignItems: "center" }}>{""}</Typography>
            )}
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="user-avatar" src={""} sx={{ border: "solid 1.5px", borderColor: "secondary.main" }} />
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUserMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUserMenu)}
                onClose={handleCloseUserMenu}
              >
                {/* TODO: Profile Page */}
                {/* <MenuItem key="Profile" onClick={handleCloseUserMenu}>
                  <RouterNavLink to="/profile" style={{ textDecoration: "none", color: "inherit" }}>
                    Profile
                  </RouterNavLink>
                </MenuItem> */}
                <MenuItem key="Log Out" onClick={logoutWithRedirect}>
                  Log Out
                </MenuItem>
              </Menu>
            </Box>
          </Stack>
        </Fragment>
      </Toolbar>
      {props.children}
      {isDrawerOpen && renderDrawer()}
    </AppBar>
  );
};

NavBar.propTypes = {
  isDesktopScreenSize: PropTypes.bool.isRequired,
};

export default NavBar;
