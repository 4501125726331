import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Pagination,
  PaginationItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { searchTransactionsByUserByDateRange } from "../api/budget";
import { SEARCH_TRANSACTIONS_DATE_RANGE_FILTER } from "../utils/constants";
import { getDateRangeGivenNetWorthInputString } from "../utils/netWorth";
import { sortTransactions } from "../utils/sort";
import { useAuth } from "./auth/AuthProvider";

const TransactionSearchInput = (props) => {
  const { accessToken, userId } = useAuth();

  const [searchString, setSearchString] = useState("");
  const [isError, setIsError] = useState(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [fixedDateRangeSelected, setFixedDateRangeSelected] = useState(SEARCH_TRANSACTIONS_DATE_RANGE_FILTER["3M"]);
  const [startDate, setStartDate] = useState(dayjs().subtract(3, "month").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [transactionLastEvaluatedKey, setTransactionLastEvaluatedKey] = useState("");

  const initialFilterDialogState = useRef({});

  useEffect(() => {
    if (searchString === "") {
      handleCancelSearchTransactions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  const fetchSearchedTransactions = (userId, token, LastEvaluatedKey = null) => {
    setIsError(false);
    props.setIsTransactionsLoading(true);
    props.setIsTransactionSearchActive(true);
    searchTransactionsByUserByDateRange(userId, startDate, endDate, searchString, token, LastEvaluatedKey)
      .then((response) => {
        if (response.transactions) {
          let newTransactionsObject = LastEvaluatedKey ? { ...props.transactions } : {};
          newTransactionsObject[props.currentPageRef.current] = sortTransactions(response.transactions);
          props.setTransactions(newTransactionsObject);
          setTransactionLastEvaluatedKey(response.LastEvaluatedKey ? response.LastEvaluatedKey : null);
          props.setIsTransactionsLoading(false);
        }
      })
      .catch((error) => {
        setIsError(true);
        props.setIsTransactionsLoading(false);
        props.setIsTransactionSearchActive(false);
      });
  };

  const handleSearchTransactions = () => {
    props.resetPagination();
    fetchSearchedTransactions(userId, accessToken);
  };

  const handleNextPageButtonClicked = () => {
    props.totalPageRef.current = props.totalPageRef.current + 1;
    props.currentPageRef.current = props.currentPageRef.current + 1;
    props.setTotalPages(props.totalPages + 1);
    props.setCurrentPage(props.currentPage + 1);
    fetchSearchedTransactions(userId, accessToken, transactionLastEvaluatedKey);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchTransactions();
    }
  };

  const handleCancelSearchTransactions = () => {
    setSearchString("");
    props.resetPagination();
    props.setIsTransactionSearchActive(false);
    props.fetchTransactionsByUser(userId, accessToken);
  };

  const handleFilterDialogOpen = () => {
    setIsFilterDialogOpen(true);
    initialFilterDialogState.current = {
      fixedDateRangeSelected: fixedDateRangeSelected,
      startDate: startDate,
      endDate: endDate,
    };
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.format("YYYY-MM-DD"));
    setFixedDateRangeSelected(SEARCH_TRANSACTIONS_DATE_RANGE_FILTER["CUSTOM"]);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.format("YYYY-MM-DD"));
    setFixedDateRangeSelected(SEARCH_TRANSACTIONS_DATE_RANGE_FILTER["CUSTOM"]);
  };

  const handleFiltersCanceled = () => {
    setIsFilterDialogOpen(false);
    setFixedDateRangeSelected(initialFilterDialogState.current.fixedDateRangeSelected);
    setStartDate(initialFilterDialogState.current.startDate);
    setEndDate(initialFilterDialogState.current.endDate);
    initialFilterDialogState.current = {};
  };

  const handleFiltersApplied = () => {
    setIsFilterDialogOpen(false);
    if (searchString !== "") {
      handleSearchTransactions();
    }
  };

  const handleFixedDateRangeToggleButtonClicked = (event, newButtonClicked) => {
    setFixedDateRangeSelected(newButtonClicked);
    if (newButtonClicked !== SEARCH_TRANSACTIONS_DATE_RANGE_FILTER["CUSTOM"]) {
      const dates = getDateRangeGivenNetWorthInputString(newButtonClicked);
      setStartDate(dates.dateStart);
      setEndDate(dates.dateEnd);
    }
  };

  const renderFilterDialog = () => {
    return (
      <Dialog
        open={isFilterDialogOpen}
        onClose={setIsFilterDialogOpen}
        fullWidth
        fullScreen={!props.isDesktopScreenSize}
      >
        <DialogTitle>Search Date Range</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ marginBottom: "20px" }}>
            The search results will only contain transactions between this date range.
          </Typography>
          <ToggleButtonGroup
            size="small"
            value={fixedDateRangeSelected}
            color="primary"
            exclusive
            onChange={handleFixedDateRangeToggleButtonClicked}
            fullWidth
          >
            {Object.keys(SEARCH_TRANSACTIONS_DATE_RANGE_FILTER).map((key) => {
              return (
                <ToggleButton key={key} value={SEARCH_TRANSACTIONS_DATE_RANGE_FILTER[key]}>
                  {SEARCH_TRANSACTIONS_DATE_RANGE_FILTER[key]}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
          <MobileDatePicker
            label="Start Date"
            value={dayjs(startDate)}
            onChange={handleStartDateChange}
            closeOnSelect={true}
            orientation="portrait"
            sx={{ width: "100%", marginTop: "20px" }}
          />
          <MobileDatePicker
            label="End Date"
            value={dayjs(endDate)}
            onChange={handleEndDateChange}
            closeOnSelect={true}
            orientation="portrait"
            sx={{ width: "100%", marginTop: "20px" }}
          />
        </DialogContent>
        <DialogActions sx={!props.isDesktopScreenSize && { marginBottom: "1rem" }}>
          <Button variant="outlined" size="large" onClick={handleFiltersCanceled} sx={{ width: "120px" }}>
            Cancel
          </Button>
          <Button variant="contained" size="large" onClick={handleFiltersApplied} sx={{ width: "120px" }}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderSearchPagination = () => {
    return (
      <Pagination
        count={props.totalPages}
        page={props.currentPage}
        variant="outlined"
        shape="rounded"
        onChange={(event, page) => {
          props.currentPageRef.current = page;
          props.setCurrentPage(page);
        }}
        renderItem={(item) => {
          if (item.type === "next") {
            return (
              <PaginationItem
                {...item}
                disabled={
                  props.currentPageRef.current === props.totalPageRef.current
                    ? transactionLastEvaluatedKey === null
                    : false
                }
                onClick={
                  props.currentPageRef.current === props.totalPageRef.current
                    ? handleNextPageButtonClicked
                    : item.onClick
                }
                size="large"
              />
            );
          }
          return <PaginationItem {...item} size="large" />;
        }}
        sx={{ float: "right" }}
      />
    );
  };

  const renderSearchBar = () => {
    return (
      <TextField
        name="search"
        label="Search"
        variant="outlined"
        value={searchString}
        onChange={(event) => setSearchString(event.target.value)}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: isError && (
            <InputAdornment position="start">
              <Tooltip title="An error occurred during the search. Please try again later.">
                <ErrorOutlineIcon color="error" sx={{ cursor: "pointer" }} />
              </Tooltip>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchString !== "" && (
                <IconButton onClick={handleCancelSearchTransactions} size="small">
                  <CloseIcon />
                </IconButton>
              )}
              <IconButton onClick={handleFilterDialogOpen} size="small">
                <FilterAltIcon />
              </IconButton>
              <IconButton onClick={handleSearchTransactions} size="small">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={
          props.isDesktopScreenSize
            ? { float: "right", marginRight: "10px", maxWidth: "95vw", width: "320px" }
            : { width: "100%" }
        }
        size="small"
      />
    );
  };

  return (
    !props.isTransactionsLoading && (
      <>
        {props.isTransactionSearchActive && renderSearchPagination()}
        {renderSearchBar()}
        {isFilterDialogOpen && renderFilterDialog()}
      </>
    )
  );
};

TransactionSearchInput.propTypes = {
  isDesktopScreenSize: PropTypes.bool.isRequired,
  transactions: PropTypes.object.isRequired,
  setTransactions: PropTypes.func.isRequired,
  isTransactionsLoading: PropTypes.bool.isRequired,
  setIsTransactionsLoading: PropTypes.func.isRequired,
  fetchTransactionsByUser: PropTypes.func.isRequired,
  isTransactionSearchActive: PropTypes.bool.isRequired,
  setIsTransactionSearchActive: PropTypes.func.isRequired,
  resetPagination: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  currentPageRef: PropTypes.object.isRequired,
  totalPages: PropTypes.number.isRequired,
  setTotalPages: PropTypes.func.isRequired,
  totalPageRef: PropTypes.object.isRequired,
};

export default TransactionSearchInput;
