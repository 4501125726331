import React, { Fragment, useEffect, useState } from "react";

import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ChartsLegend } from "@mui/x-charts/ChartsLegend";
import { ChartsTooltip } from "@mui/x-charts/ChartsTooltip";
import { PiePlot, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";
import PropTypes from "prop-types";
import { getTransactionsByUserByDate, readTransaction } from "../api/budget";
import { useAuth } from "../components/auth/AuthProvider";
import { useCategory } from "../components/category/CategoryProvider";
import CategorySettingsDialog from "../components/category/CategorySettingsDialog";
import Hero from "../components/Hero";
import Transaction from "../components/Transaction";
import { getTransactionCategory } from "../utils/common";
import { formatIntToMoney } from "../utils/customFormats";
import { sortTransactionsArray } from "../utils/sort";

export const Trends = (props) => {
  const { accessToken, userId, isAuthenticated } = useAuth();
  const { pkToCategoryMap, mainFriendlyNameToCategoryMap } = useCategory();
  // Page State
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedChart, setSelectedChart] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [isCategorySettingsDialogOpen, setIsCategorySettingsDialogOpen] = useState(false);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);
  const [loadError, setLoadError] = useState("");

  useEffect(() => {
    if (isAuthenticated()) {
      fetchTransactionsByUserByDate(
        userId,
        accessToken,
        `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, "0")}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, selectedDate]);

  const fetchTransactionsByUserByDate = (userId, token, date) => {
    setIsTransactionsLoading(true);
    setLoadError("");
    getTransactionsByUserByDate(userId, token, date)
      .then((response) => {
        setTransactions(response.transactions);
        setIsTransactionsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoadError("Unexpected Error");
        setIsTransactionsLoading(false);
      });
  };

  const getTotalIncome = (transactions) => {
    let totalIncome = 0;
    for (const txn of transactions) {
      const txnCategory = pkToCategoryMap?.get(JSON.stringify(txn.appCategory));
      if (txnCategory?.primary === "INCOME") {
        totalIncome -= txn.amount;
      }
    }
    return totalIncome;
  };

  const getTotalExpenses = (transactions) => {
    let totalExpenses = 0;
    for (const txn of transactions) {
      const txnCategory = pkToCategoryMap?.get(JSON.stringify(txn.appCategory));
      if (
        txnCategory?.primary !== "TRANSFER_IN" &&
        txnCategory?.primary !== "TRANSFER_OUT" &&
        txnCategory?.primary !== "INCOME"
      ) {
        totalExpenses += txn.amount;
      }
    }
    return totalExpenses;
  };

  const getTotalTransferDiff = (transactions) => {
    let totalTransferDiff = 0;
    for (const txn of transactions) {
      const txnCategory = pkToCategoryMap?.get(JSON.stringify(txn.appCategory));
      if (txnCategory?.primary === "TRANSFER_IN") {
        totalTransferDiff += Math.abs(txn.amount);
      }
      if (txnCategory?.primary === "TRANSFER_OUT") {
        totalTransferDiff -= Math.abs(txn.amount);
      }
    }
    return totalTransferDiff;
  };

  const getIncomeSeriesData = (transactions) => {
    let series = [];
    for (const txn of transactions) {
      const txnCategory = pkToCategoryMap?.get(JSON.stringify(txn.appCategory));
      if (txnCategory?.primary === "INCOME") {
        const existingCategory = series.find((category) => category.id === txnCategory?.detailed);
        if (existingCategory) {
          existingCategory.value -= txn.amount;
        } else {
          const newCategory = {
            id: txnCategory?.detailed,
            value: -txn.amount,
            label: txnCategory?.subFriendlyName,
            color: txnCategory?.subColor ? txnCategory?.subColor : null,
          };
          series.push(newCategory);
        }
      }
    }
    return series;
  };

  const getExpenseSeriesData = (transactions) => {
    let series = [];
    for (const txn of transactions) {
      let txnCategory = getTransactionCategory(pkToCategoryMap, mainFriendlyNameToCategoryMap, txn);
      if (
        txnCategory?.primary !== "TRANSFER_IN" &&
        txnCategory?.primary !== "TRANSFER_OUT" &&
        txnCategory?.primary !== "INCOME"
      ) {
        const existingCategory = series.find((category) => category.id === txnCategory?.primary);
        if (existingCategory) {
          existingCategory.value += txn.amount;
        } else {
          const newCategory = {
            id: txnCategory?.primary,
            value: txn.amount,
            label: txnCategory?.mainFriendlyName,
            color: txnCategory?.mainColor ? txnCategory?.mainColor : null,
          };
          series.push(newCategory);
        }
      }
    }
    return series;
  };

  const getTransferSeriesData = (transactions) => {
    let series = [];
    for (const txn of transactions) {
      const txnCategory = pkToCategoryMap?.get(JSON.stringify(txn.appCategory));
      if (txnCategory?.primary === "TRANSFER_IN" || txnCategory?.primary === "TRANSFER_OUT") {
        const existingCategory = series.find((category) => category.id === txnCategory?.primary);
        if (existingCategory) {
          existingCategory.value += Math.abs(txn.amount);
        } else {
          const newCategory = {
            id: txnCategory?.primary,
            value: Math.abs(txn.amount),
            label: txnCategory?.mainFriendlyName,
            color: txnCategory?.mainColor ? txnCategory?.mainColor : null,
          };
          series.push(newCategory);
        }
      }
    }
    return series;
  };

  const handleReadTransaction = (transaction, isRead = null) => {
    const readValue = isRead ? isRead : !transaction.isRead;
    readTransaction(userId, transaction.transaction_id, readValue, accessToken);
    transaction.isRead = readValue;
  };

  const handleYearLeftArrowClicked = () => {
    const newDate = new Date(selectedDate);
    newDate.setFullYear(newDate.getFullYear() - 1);
    setSelectedDate(newDate);
  };

  const handleYearRightArrowClicked = () => {
    const newDate = new Date(selectedDate);
    newDate.setFullYear(newDate.getFullYear() + 1);
    setSelectedDate(newDate);
  };

  const handleMonthClicked = (monthIdx) => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(monthIdx);
    setSelectedDate(newDate);
  };

  const handlePieChartSectionClicked = (event, chart, category) => {
    setSelectedChart(chart.seriesId);
    setSelectedCategory(category);
  };

  const handleBackClicked = () => {
    setSelectedChart("");
    setSelectedCategory("");
  };

  const renderYear = () => {
    return (
      <Stack direction="row" sx={{ alignItems: "center" }}>
        <IconButton onClick={handleYearLeftArrowClicked}>
          <ArrowCircleLeftIcon fontSize="large" color="primary" />
        </IconButton>
        <TextField value={selectedDate.getFullYear()} disabled sx={{ width: "70px" }} size="small"></TextField>
        <IconButton onClick={handleYearRightArrowClicked}>
          <ArrowCircleRightIcon fontSize="large" color="primary" />
        </IconButton>
      </Stack>
    );
  };

  const renderMonths = () => {
    const MONTH_LIST = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    return (
      <Box sx={{ margin: "10px" }}>
        {MONTH_LIST.map((month, idx) => {
          return (
            <Button
              key={month}
              variant={
                selectedDate?.toLocaleString("default", { month: "short" }).toUpperCase() === month
                  ? "contained"
                  : "outlined"
              }
              onClick={() => handleMonthClicked(idx)}
              sx={{ margin: "2px" }}
              size="large"
            >
              {month}
            </Button>
          );
        })}
      </Box>
    );
  };

  const renderTransactions = (txns) => {
    if (txns.length > 0) {
      return txns.map((txn, idx) => {
        return (
          <Transaction
            key={txn.transaction_id}
            transaction={txn}
            onReadTransaction={handleReadTransaction}
            isDesktopScreenSize={props.isDesktopScreenSize}
            idx={idx}
            setIsCategorySettingsDialogOpen={setIsCategorySettingsDialogOpen}
          />
        );
      });
    } else {
      return <Typography sx={{ margin: "20px" }}>No Transactions :(</Typography>;
    }
  };

  const renderChart = (id, series) => {
    return (
      <ResponsiveChartContainer
        series={[
          {
            id: id,
            type: "pie",
            data: series,
            highlightScope: { faded: "global", highlighted: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "#455a64" },
            innerRadius: 45,
            outerRadius: 190,
            paddingAngle: 2,
            cornerRadius: 5,
            cy: 180,
            arcLabel: selectedChart === "" ? "formattedValue" : undefined,
            arcLabelMinAngle: 20,
            arcLabelRadius: 145,
            valueFormatter: (value) => {
              return `${formatIntToMoney(value.value)}`;
            },
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "white",
            fontWeight: "bold",
            whiteSpace: "pre",
          },
        }}
      >
        <PiePlot onClick={handlePieChartSectionClicked} />
        {selectedChart === "" && <ChartsTooltip trigger="item" />}
        <ChartsLegend
          direction="row"
          position={{ horizontal: "middle", vertical: "bottom" }}
          slotProps={{ legend: { padding: -5 } }}
        />
      </ResponsiveChartContainer>
    );
  };

  const renderCharts = () => {
    return isTransactionsLoading ? (
      <LinearProgress />
    ) : loadError !== "" ? (
      <Alert severity="error" variant="filled" sx={{ margin: "20px" }}>
        {loadError}
      </Alert>
    ) : transactions.length > 0 ? (
      <Grid container>
        {(selectedChart === "" || selectedChart === "Total Income") && (
          <Grid item sm={12} md={selectedChart === "Total Income" ? 12 : 4}>
            <Stack direction="row">
              <Stack direction="column" sx={{ marginLeft: "5rem" }}>
                <Typography
                  variant="caption"
                  sx={{ color: "primary.main", fontWeight: "bold", marginBottom: "-8px", fontSize: "16px" }}
                >
                  TOTAL INCOME
                </Typography>
                <Typography variant="h4" sx={{ color: "primary.main" }}>
                  {formatIntToMoney(getTotalIncome(transactions))}
                </Typography>
              </Stack>
              {selectedChart !== "" && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: "10px" }} />
                  <Stack direction="column">
                    <Typography
                      variant="caption"
                      sx={{ color: "primary.main", fontWeight: "bold", marginBottom: "-8px", fontSize: "16px" }}
                    >
                      TOTAL {selectedCategory?.label.toUpperCase()}
                    </Typography>
                    <Typography variant="h4" sx={{ color: "primary.main" }}>
                      {formatIntToMoney(selectedCategory.value)}
                    </Typography>
                  </Stack>
                  <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: "10px" }} />
                  <Button variant="contained" sx={{ width: "100px", height: "40px" }} onClick={handleBackClicked}>
                    Back
                  </Button>
                </Box>
              )}
            </Stack>
          </Grid>
        )}
        {(selectedChart === "" || selectedChart === "Total Expenses") && (
          <Grid item sm={12} md={selectedChart === "Total Expenses" ? 12 : 4}>
            <Stack direction="row">
              <Stack direction="column" sx={{ marginLeft: "5rem" }}>
                <Typography
                  variant="caption"
                  sx={{ color: "primary.main", fontWeight: "bold", marginBottom: "-8px", fontSize: "16px" }}
                >
                  TOTAL EXPENSES
                </Typography>
                <Typography variant="h4" sx={{ color: "primary.main" }}>
                  {formatIntToMoney(getTotalExpenses(transactions))}
                </Typography>
              </Stack>

              {selectedChart !== "" && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: "10px" }} />
                  <Stack direction="column">
                    <Typography
                      variant="caption"
                      sx={{ color: "primary.main", fontWeight: "bold", marginBottom: "-8px", fontSize: "16px" }}
                    >
                      TOTAL {selectedCategory?.label?.toUpperCase()}
                    </Typography>
                    <Typography variant="h4" sx={{ color: "primary.main" }}>
                      {formatIntToMoney(selectedCategory?.value)}
                    </Typography>
                  </Stack>
                  <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: "10px" }} />
                  <Button variant="contained" sx={{ width: "100px", height: "40px" }} onClick={handleBackClicked}>
                    Back
                  </Button>
                </Box>
              )}
            </Stack>
          </Grid>
        )}
        {(selectedChart === "" || selectedChart === "Total Transfer Diff") && (
          <Grid item sm={12} md={selectedChart === "Total Transfer Diff" ? 12 : 4}>
            <Stack direction="row">
              <Stack direction="column" sx={{ marginLeft: "5rem" }}>
                <Typography
                  variant="caption"
                  sx={{ color: "primary.main", fontWeight: "bold", marginBottom: "-8px", fontSize: "16px" }}
                >
                  TOTAL TRANSFER DIFF
                </Typography>
                <Typography variant="h4" sx={{ color: "primary.main" }}>
                  {formatIntToMoney(getTotalTransferDiff(transactions))}
                </Typography>
              </Stack>
              {selectedChart !== "" && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: "10px" }} />
                  <Stack direction="column">
                    <Typography
                      variant="caption"
                      sx={{ color: "primary.main", fontWeight: "bold", marginBottom: "-8px", fontSize: "16px" }}
                    >
                      TOTAL {selectedCategory?.label.toUpperCase()}
                    </Typography>
                    <Typography variant="h4" sx={{ color: "primary.main" }}>
                      {formatIntToMoney(selectedCategory?.value)}
                    </Typography>
                  </Stack>
                  <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: "10px" }} />
                  <Button variant="contained" sx={{ width: "100px", height: "40px" }} onClick={handleBackClicked}>
                    Back
                  </Button>
                </Box>
              )}
            </Stack>
          </Grid>
        )}
        {(selectedChart === "" || selectedChart === "Total Income") && (
          <Grid item sm={12} md={4} height="550px">
            {renderChart("Total Income", getIncomeSeriesData(transactions))}
          </Grid>
        )}
        {(selectedChart === "" || selectedChart === "Total Expenses") && (
          <Grid item sm={12} md={4} height="550px">
            {renderChart("Total Expenses", getExpenseSeriesData(transactions))}
          </Grid>
        )}
        {(selectedChart === "" || selectedChart === "Total Transfer Diff") && (
          <Grid item sm={12} md={4} height="550px">
            {renderChart("Total Transfer Diff", getTransferSeriesData(transactions))}
          </Grid>
        )}
        {selectedChart !== "" && (
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                margin: "1rem",
                height: "60vh",
                overflowY: "scroll",
                "::-webkit-scrollbar": { WebkitAppearance: "none", width: "7px" },
                "::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  backgroundColor: "#115247",
                  WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, .5)",
                },
              }}
            >
              {renderTransactions(
                sortTransactionsArray(
                  transactions.filter((txn) => {
                    const txnCategory = getTransactionCategory(pkToCategoryMap, mainFriendlyNameToCategoryMap, txn);
                    return (
                      txnCategory?.primary === selectedCategory.id || txnCategory?.detailed === selectedCategory.id
                    );
                  })
                )
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    ) : (
      <Typography variant="H4" sx={{ marginLeft: "5rem", marginTop: "2rem" }}>
        NO TRANSACTIONS
      </Typography>
    );
  };

  const renderAuthenticatedTrendsPage = () => {
    return (
      <Box sx={{ backgroundColor: "secondary.main" }}>
        <Stack direction="column" sx={{ height: "92vh" }}>
          <Stack direction="row">
            {renderYear()}
            {renderMonths()}
          </Stack>
          {renderCharts()}
        </Stack>
        {isCategorySettingsDialogOpen && (
          <CategorySettingsDialog
            isDialogOpen={isCategorySettingsDialogOpen}
            handleDialogClosed={() => setIsCategorySettingsDialogOpen(false)}
            isDesktopScreenSize={props.isDesktopScreenSize}
          />
        )}
      </Box>
    );
  };

  return <Fragment>{isAuthenticated() ? renderAuthenticatedTrendsPage() : <Hero />}</Fragment>;
};

Trends.propTypes = {
  isDesktopScreenSize: PropTypes.bool.isRequired,
};

export default Trends;
