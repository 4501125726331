export const formatIntToMoney = (number) => {
  if (number || number === 0) {
    return number.toLocaleString("en-US", { style: "currency", currency: "USD" });
  }
  return "N/A";
};

export const formatIntToMoneyAbsolute = (number) => {
  if (number || number === 0) {
    return Math.abs(number).toLocaleString("en-US", { style: "currency", currency: "USD" });
  }
  return "N/A";
};
