import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Router } from "react-router-dom";
import App from "./App";
import theme from "./assets/Theme";
import { AuthProvider } from "./components/auth/AuthProvider";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import history from "./utils/history";

const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </LocalizationProvider>
      </Router>
    </ThemeProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
