import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

const PasswordInput = ({ password, setPassword, error, validatePassword, label }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    validatePassword(value);
  };

  return (
    <TextField
      variant="filled"
      type={showPassword ? "text" : "password"}
      label={label ? label : "Password"}
      fullWidth
      value={password}
      onChange={handlePasswordChange}
      onBlur={(event) => validatePassword(event.target.value)}
      error={!!error}
      helperText={error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        marginTop: "10px",
        marginBottom: "10px",
        backgroundColor: "secondary.main",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
      }}
    />
  );
};

export default PasswordInput;
