import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Chip, CircularProgress, IconButton, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { addCategory } from "../../api/category";
import { useAuth } from "../auth/AuthProvider";
import { useCategory } from "./CategoryProvider";

const AddSubCategoryButton = (props) => {
  const { accessToken, userId } = useAuth();
  const { fetchCategoriesByUser } = useCategory();
  const [isNewSubCategoryInputActive, setIsNewSubCategoryInputActive] = useState(false);
  const [newSubCategory, setNewSubCategory] = useState("");
  const [isAddLoading, setIsAddLoading] = useState(false);

  const closeForm = () => {
    setIsAddLoading(false);
    setIsNewSubCategoryInputActive(false);
    setNewSubCategory("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubCategoryCreate();
    }
  };

  const handleSubCategoryCreate = () => {
    setIsAddLoading(true);
    addCategory(userId, props.mainFriendlyName, newSubCategory, accessToken).then(() => {
      fetchCategoriesByUser();
      closeForm();
    });
  };

  const handleNewSubCategoryClicked = () => {
    if (!isNewSubCategoryInputActive) {
      setIsNewSubCategoryInputActive(true);
    }
  };

  if (isNewSubCategoryInputActive) {
    return (
      <TextField
        placeholder="New Sub Category"
        inputRef={(input) => input?.focus()}
        variant="outlined"
        value={newSubCategory}
        onChange={(event) => setNewSubCategory(event.target.value)}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={closeForm} size="small" disabled={isAddLoading}>
                <CloseIcon />
              </IconButton>
              <IconButton onClick={() => handleSubCategoryCreate()} size="small">
                {isAddLoading ? <CircularProgress size={18} /> : <CheckIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          height: "50px",
          margin: "5px",
        }}
      />
    );
  } else {
    return (
      <Chip
        key={`${props.mainFriendlyName}-add-new-sub-category`}
        label="ADD"
        onClick={handleNewSubCategoryClicked}
        variant="outlined"
        color="primary"
        icon={<AddIcon />}
        sx={{
          borderRadius: "5px",
          margin: "5px",
          height: "50px",
          width: "100px",
        }}
      />
    );
  }
};

AddSubCategoryButton.propTypes = {
  mainFriendlyName: PropTypes.string.isRequired,
};

export default AddSubCategoryButton;
