import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import {
  addItem,
  getCustomAssetsByUser,
  getItemsByUser,
  getNetWorthByUserByDateRange,
  getTransactionsByUser,
  getUserConfigByUserId,
  readTransaction,
  toggleUserPrivacyFilter,
} from "../api/budget";
import { getLinkToken } from "../api/plaid";
import Account from "../components/Account";
import { useAuth } from "../components/auth/AuthProvider";
import CategorySettingsDialog from "../components/category/CategorySettingsDialog";
import CustomAsset from "../components/CustomAsset";
import CustomAssetDialog from "../components/CustomAssetDialog";
import SyncSnackbar from "../components/SyncSnackbar";
import Transaction from "../components/Transaction";
import TransactionSearchInput from "../components/TransactionSearchInput";
import { useWebSocket } from "../components/websocket/WebSocketProvider";
import { getAccount } from "../utils/common";
import { ACCOUNT_GROUPINGS, NET_WORTH_DATE_FILTER } from "../utils/constants";
import { formatIntToMoney } from "../utils/customFormats";
import { getDateRangeGivenNetWorthInputString, getMaxYAxis, getMinYAxis } from "../utils/netWorth";
import { sortTransactions } from "../utils/sort";

const Home = (props) => {
  const { accessToken, userId, isAuthenticated, clearSession } = useAuth();
  // Web Socket
  const { ws, messages, getNextMessage } = useWebSocket();
  // Custom State
  const [userConfig, setUserConfig] = useState(null);
  const [items, setItems] = useState([]);
  const [isItemsLoading, setIsItemsLoading] = useState(true);
  const [fetchItemsErrorMessage, setFetchItemsErrorMessage] = useState("");
  const [cashAccounts, setCashAccounts] = useState([]);
  const [creditAccounts, setCreditAccounts] = useState([]);
  const [loanAccounts, setLoanAccounts] = useState([]);
  const [investmentAccounts, setInvestmentAccounts] = useState([]);
  const [noAccounts, setNoAccounts] = useState([]);
  const [customAssetAccounts, setCustomAssetAccounts] = useState([]);
  const [transactions, setTransactions] = useState({});
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);
  const [fetchTransactionsErrorMessage, setFetchTransactionsErrorMessage] = useState("");
  const [transactionLastEvaluatedKey, setTransactionLastEvaluatedKey] = useState(null);
  const [mainErrorMessage, setMainErrorMessage] = useState("");
  const [netWorthData, setNetWorthData] = useState([]);
  const [netWorthChartRange, setNetWorthChartRange] = useState(NET_WORTH_DATE_FILTER["30D"]);
  const [isNetWorthDataLoading, setIsNetWorthDataLoading] = useState(false);
  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState(null);
  const [netWorthDateFilterMenuAnchorEl, setNetWorthDateFilterMenuAnchorEl] = useState(null);
  const isAddMenuOpened = Boolean(addMenuAnchorEl);
  const isNetWorthDateFilterMenuOpened = Boolean(netWorthDateFilterMenuAnchorEl);
  const [isAccountAddLoading, setIsAccountAddLoading] = useState(false);
  const [isNewItemAdded, setIsNewItemAdded] = useState(false);
  const [isTransactionSearchActive, setIsTransactionSearchActive] = useState(false);

  const [isCategorySettingsDialogOpen, setIsCategorySettingsDialogOpen] = useState(false);
  const [isAddCustomAssetDialogOpen, setIsAddCustomAssetDialogOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const currentPageRef = useRef(1);
  const totalPageRef = useRef(1);

  // Plaid State
  const [linkToken, setLinkToken] = useState(null);
  const config = {
    token: linkToken,
    onSuccess: (publicToken, metadata) => {
      console.log(`onSuccess(${publicToken}, %j)`, metadata);
      setIsAccountAddLoading(true);
      fetchAddItem(userId, publicToken, metadata.institution.institution_id, accessToken);
    },
    onExit: (error, metadata) => {
      console.log(`onExit(${error}, ${metadata})`);
    },
    onEvent: (eventName, metadata) => {
      console.log(`onEvent(${eventName}, ${metadata})`);
    },
  };
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isAuthenticated()) {
      fetchUserConfig(userId, accessToken);
      fetchItemsByUser(userId, accessToken);
      fetchCustomAssetsByUser(userId, accessToken);
      fetchTransactionsByUser(userId, accessToken);
      fetchLinkToken(userId, accessToken);
      fetchNetWorthDataByUserByDateRange(userId, netWorthChartRange, accessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, userId]);

  useEffect(() => {
    if (messages.length > 0) {
      const nextMessage = getNextMessage();
      handleMessage(nextMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws, messages, getNextMessage]);

  const handleMessage = (message) => {
    switch (message.messageType) {
      case "ITEM":
        fetchItemsByUser(userId, accessToken);
        break;
      case "NET_WORTH_DATA":
        fetchNetWorthDataByUserByDateRange(userId, netWorthChartRange, accessToken);
        break;
      case "TXN_CURSOR":
        fetchTransactionsByUser(userId, accessToken);
        break;
      default:
        console.log("Unknown WS message: %j", message);
    }
  };

  const fetchUserConfig = (userId, token) => {
    getUserConfigByUserId(userId, token)
      .then((response) => {
        if (response.statusCode === 403) {
          clearSession();
        } else {
          setUserConfig(response.record);
        }
      })
      .catch((e) => {
        setMainErrorMessage("Unexpected Error.");
      });
  };

  const fetchLinkToken = (userId, token) => {
    getLinkToken(userId, token)
      .then((response) => {
        if (response.statusCode === 403) {
          clearSession();
        } else {
          setLinkToken(response.linkToken);
        }
      })
      .catch((e) => {
        setMainErrorMessage("Unexpected Error.");
      });
  };

  const fetchItemsByUser = (userId, token) => {
    setIsItemsLoading(true);
    getItemsByUser(userId, token)
      .then((response) => {
        if (response.statusCode === 403) {
          clearSession();
        } else {
          let cashAccounts = [];
          response.items.forEach((item) => {
            item?.accounts?.forEach((account) => {
              if (ACCOUNT_GROUPINGS.CASH.includes(account.type)) {
                account.sk = item.sk;
                account.institutionId = item.institutionId;
                account.isError = item.isError;
                cashAccounts.push(account);
              }
            });
          });
          setCashAccounts(cashAccounts);
          let creditAccounts = [];
          response.items.forEach((item) => {
            item?.accounts?.forEach((account) => {
              if (ACCOUNT_GROUPINGS.CREDIT.includes(account.type)) {
                account.sk = item.sk;
                account.institutionId = item.institutionId;
                account.isError = item.isError;
                creditAccounts.push(account);
              }
            });
          });
          setCreditAccounts(creditAccounts);
          let loanAccounts = [];
          response.items.forEach((item) => {
            item?.accounts?.forEach((account) => {
              if (ACCOUNT_GROUPINGS.LOANS.includes(account.type)) {
                account.sk = item.sk;
                account.institutionId = item.institutionId;
                account.isError = item.isError;
                loanAccounts.push(account);
              }
            });
          });
          setLoanAccounts(loanAccounts);
          let investmentAccounts = [];
          response.items.forEach((item) => {
            item?.accounts?.forEach((account) => {
              if (ACCOUNT_GROUPINGS.INVESTMENTS.includes(account.type)) {
                account.sk = item.sk;
                account.institutionId = item.institutionId;
                account.isError = item.isError;
                investmentAccounts.push(account);
              }
            });
          });
          setInvestmentAccounts(investmentAccounts);
          let noAccounts = [];
          response.items.forEach((item) => {
            if (item.accounts.length === 0) {
              noAccounts.push({ ...item, isNoAccountItem: true });
            }
          });
          setNoAccounts(noAccounts);
          setItems(response.items);
          setIsItemsLoading(false);
        }
      })
      .catch((e) => {
        setFetchItemsErrorMessage("Unexpected Error");
        setIsItemsLoading(false);
      });
  };

  const fetchCustomAssetsByUser = (userId, token) => {
    getCustomAssetsByUser(userId, token)
      .then((response) => {
        if (response.statusCode === 403) {
          clearSession();
        } else {
          setCustomAssetAccounts(response.customAssets);
        }
      })
      .catch((e) => {
        setMainErrorMessage("Unexpected Error.");
      });
  };

  const fetchTransactionsByUser = (userId, token, LastEvaluatedKey = null) => {
    setIsTransactionsLoading(true);
    getTransactionsByUser(userId, token, LastEvaluatedKey)
      .then((response) => {
        if (response.statusCode === 403) {
          clearSession();
        } else {
          let newTransactionsObject = { ...transactions };
          newTransactionsObject[currentPageRef.current] = sortTransactions(response.transactions);
          setTransactions(newTransactionsObject);
          setTransactionLastEvaluatedKey(response.LastEvaluatedKey ? response.LastEvaluatedKey : null);
          setIsTransactionsLoading(false);
        }
      })
      .catch((e) => {
        setFetchTransactionsErrorMessage("Unexpected Error");
        setIsTransactionsLoading(false);
      });
  };

  const fetchAddItem = (userId, publicToken, institutionId, token) => {
    addItem(userId, publicToken, institutionId, token)
      .then((response) => {
        if (response.statusCode === 403) {
          clearSession();
        } else if (response.error) {
          setMainErrorMessage(response.error);
        } else {
          fetchItemsByUser(userId, token);
          // fetchTransactionsByUser(userId, token);
          setIsNewItemAdded(true);
        }
      })
      .catch((e) => {
        console.log("fetchAddItem error");
        setMainErrorMessage("Unexpected Error.");
      })
      .finally(() => {
        setIsAccountAddLoading(false);
      });
  };

  const fetchNetWorthDataByUserByDateRange = (userId, dateRange, token) => {
    const dates = getDateRangeGivenNetWorthInputString(dateRange);
    setIsNetWorthDataLoading(true);
    getNetWorthByUserByDateRange(userId, dates.dateStart, dates.dateEnd, token)
      .then((response) => {
        if (response.statusCode === 403) {
          clearSession();
        } else {
          setNetWorthData(
            response.records.map((record) => {
              return {
                date: new Date(record.sk),
                netWorth: record.netWorth,
              };
            })
          );
          setIsNetWorthDataLoading(false);
        }
      })
      .catch((e) => {
        setMainErrorMessage("Unexpected Error.");
      });
  };

  const handleReadTransaction = (transaction, isRead = null) => {
    const readValue = isRead || !transaction.isRead;
    readTransaction(userId, transaction.transaction_id, readValue, accessToken);
    transaction.isRead = readValue;
  };

  const handleTransactionSearchResetPagination = () => {
    totalPageRef.current = 1;
    currentPageRef.current = 1;
    setTotalPages(1);
    setCurrentPage(1);
  };

  const handleNextPageButtonClicked = (event) => {
    totalPageRef.current = totalPageRef.current + 1;
    currentPageRef.current = currentPageRef.current + 1;
    setTotalPages(totalPages + 1);
    setCurrentPage(currentPage + 1);
    fetchTransactionsByUser(userId, accessToken, transactionLastEvaluatedKey);
  };

  const handleCloseMainError = () => {
    setMainErrorMessage("");
  };

  const handleNetWorthChartRangeToggleButtonClicked = (event, newButtonClicked) => {
    setNetWorthChartRange(newButtonClicked);
    fetchNetWorthDataByUserByDateRange(userId, newButtonClicked, accessToken);
  };

  const handleNetWorthChartRangeMenuItemClicked = (inputString) => {
    handleNetWorthDateFilterMenuClosed();
    handleNetWorthChartRangeToggleButtonClicked(null, inputString);
  };

  const handleAddMenuOpened = (event) => {
    setAddMenuAnchorEl(event.currentTarget);
  };

  const handleAddMenuClosed = () => {
    setAddMenuAnchorEl(null);
  };

  const handleNetWorthDateFilterMenuOpened = (event) => {
    setNetWorthDateFilterMenuAnchorEl(event.currentTarget);
  };

  const handleNetWorthDateFilterMenuClosed = () => {
    setNetWorthDateFilterMenuAnchorEl(null);
  };

  const handleAddBankAccountClicked = () => {
    open();
    handleAddMenuClosed();
  };

  const handleAddCustomAssetClicked = () => {
    setIsAddCustomAssetDialogOpen(true);
    handleAddMenuClosed();
  };

  const handleTogglePrivacyFilter = () => {
    toggleUserPrivacyFilter(userId, !userConfig?.isPrivacyFilterOn, accessToken);
    setUserConfig({ ...userConfig, isPrivacyFilterOn: !userConfig?.isPrivacyFilterOn });
  };

  const renderNetWorth = () => {
    let netWorth = 0;
    // Assets
    for (const account of [...cashAccounts, ...investmentAccounts]) {
      netWorth += account.balances.current;
    }
    // Custom Assets
    for (const asset of customAssetAccounts) {
      netWorth += asset.balance;
    }
    // Liabilities
    for (const account of [...creditAccounts, ...loanAccounts]) {
      netWorth -= account.balances.current;
    }
    return !userConfig?.isPrivacyFilterOn ? formatIntToMoney(netWorth) : "**********";
  };

  const renderAccounts = (accounts) => {
    return accounts.map((account) => {
      return (
        <Grid key={account.account_id} item xs={12}>
          <Account
            account={account}
            isDesktopScreenSize={props.isDesktopScreenSize}
            isPrivacyFilterOn={userConfig?.isPrivacyFilterOn}
            fetchItemsByUser={fetchItemsByUser}
          />
        </Grid>
      );
    });
  };

  const renderCustomAssets = () => {
    return customAssetAccounts.map((customAsset) => {
      return (
        <Grid key={customAsset.id} item xs={12}>
          <CustomAsset
            id={customAsset.id}
            name={customAsset.name}
            balance={customAsset.balance}
            isDesktopScreenSize={props.isDesktopScreenSize}
            isPrivacyFilterOn={userConfig?.isPrivacyFilterOn}
            onUpdateCustomAsset={fetchCustomAssetsByUser}
          />
        </Grid>
      );
    });
  };

  const renderTransactions = () => {
    if (transactions[currentPageRef.current]) {
      const transactionsList = Object.values(transactions[currentPageRef.current]);
      if (transactionsList.length > 0) {
        return transactionsList.map((transaction, idx) => {
          return (
            <Transaction
              key={transaction.transaction_id}
              transaction={transaction}
              onReadTransaction={handleReadTransaction}
              account={getAccount(transaction.account_id, items)}
              isDesktopScreenSize={props.isDesktopScreenSize}
              idx={idx}
              setIsCategorySettingsDialogOpen={setIsCategorySettingsDialogOpen}
            />
          );
        });
      } else {
        return <Typography sx={{ margin: "20px" }}>No Transactions :(</Typography>;
      }
    }
  };

  const renderNetWorthDateFilter = () => {
    if (props.isDesktopScreenSize) {
      return (
        <Stack
          direction="row"
          sx={{
            float: "right",
            marginRight: "1rem",
            marginTop: "1rem",
          }}
        >
          <ToggleButtonGroup
            size="small"
            value={netWorthChartRange}
            color="primary"
            exclusive
            onChange={handleNetWorthChartRangeToggleButtonClicked}
          >
            {Object.keys(NET_WORTH_DATE_FILTER).map((key) => {
              return (
                <ToggleButton key={key} value={NET_WORTH_DATE_FILTER[key]} sx={{ width: "50px" }}>
                  {NET_WORTH_DATE_FILTER[key]}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Stack>
      );
    } else {
      return (
        <>
          <Chip
            variant="outlined"
            label={netWorthChartRange}
            onClick={handleNetWorthDateFilterMenuOpened}
            size="large"
            sx={{
              alignItems: "center",
              display: "flex",
              width: "75px",
              marginTop: "1rem",
              marginRight: "1rem",
              float: "right",
            }}
          />
          <Menu
            id="net-worth-date-filter-menu"
            anchorEl={netWorthDateFilterMenuAnchorEl}
            open={isNetWorthDateFilterMenuOpened}
            onClose={handleNetWorthDateFilterMenuClosed}
            MenuListProps={{
              "aria-labelledby": "net-worth-date-filter-button",
            }}
          >
            {Object.keys(NET_WORTH_DATE_FILTER).map((key) => {
              return (
                <MenuItem key={key} onClick={() => handleNetWorthChartRangeMenuItemClicked(NET_WORTH_DATE_FILTER[key])}>
                  {NET_WORTH_DATE_FILTER[key]}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      );
    }
  };

  const renderLoadingScreen = () => {
    return <LinearProgress sx={{ height: "100vh", backgroundColor: "secondary.dark" }} />;
  };

  const renderMainScreen = () => {
    return (
      <Box
        sx={{
          padding: props.isDesktopScreenSize ? "0px 4rem 4rem 4rem" : "10px",
          height: "90vh",
          overflowY: "scroll",
        }}
      >
        <Grid container>
          {/* Net Worth Paper */}
          <Grid item xs={12} md={7} sx={{ marginTop: "10px", paddingRight: props.isDesktopScreenSize ? "5px" : "0px" }}>
            <Card
              sx={{
                minHeight: props.isDesktopScreenSize ? "380px" : "220px",
                backgroundColor: "#e0e0e0",
                borderRadius: "10px",
              }}
            >
              <Stack
                direction="row"
                sx={{
                  float: "left",
                  marginLeft: "1rem",
                  marginTop: "1rem",
                  alignItems: "center",
                }}
              >
                <Stack direction="column">
                  <Typography
                    variant="caption"
                    sx={{ color: "primary.main", fontWeight: "bold", marginBottom: "-8px" }}
                  >
                    NET WORTH
                  </Typography>
                  <Typography variant="h5">{renderNetWorth()}</Typography>
                </Stack>
                <IconButton
                  onClick={() => handleTogglePrivacyFilter()}
                  aria-label="read"
                  sx={{ color: "primary.main", marginLeft: "5px" }}
                >
                  {!userConfig?.isPrivacyFilterOn ? (
                    <VisibilityOffIcon fontSize="inherit" />
                  ) : (
                    <VisibilityIcon fontSize="inherit" />
                  )}
                </IconButton>
              </Stack>
              {renderNetWorthDateFilter()}
              {isNetWorthDataLoading ? (
                <LinearProgress sx={{ margin: "auto", marginTop: "200px", width: "200px" }} />
              ) : (
                <LineChart
                  xAxis={[
                    {
                      dataKey: "date",
                      scaleType: "point",
                      valueFormatter: (value) => {
                        return value.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit" });
                      },
                    },
                  ]}
                  yAxis={[
                    {
                      valueFormatter: (value) => {
                        const suffixes = ["", "k", "m", "b", "t"];
                        const magnitude = Math.floor(Math.log10(value) / 3);
                        const convertedNumber = value / Math.pow(1000, magnitude);
                        return !userConfig?.isPrivacyFilterOn
                          ? value === 0
                            ? 0
                            : `${convertedNumber}${suffixes[magnitude]}`
                          : "*";
                      },
                      min: getMinYAxis(netWorthData),
                      max: getMaxYAxis(netWorthData),
                    },
                  ]}
                  series={[
                    {
                      curve: "linear",
                      dataKey: "netWorth",
                      color: "#115247",
                      area: true,
                    },
                  ]}
                  dataset={netWorthData}
                  tooltip={{ trigger: !userConfig?.isPrivacyFilterOn ? "axis" : "none" }}
                  height={props.isDesktopScreenSize ? 325 : 250}
                />
              )}
            </Card>
          </Grid>
          {/* My Accounts Paper */}
          <Grid item xs={12} md={5} sx={{ marginTop: "10px", paddingLeft: props.isDesktopScreenSize ? "5px" : "0px" }}>
            <Card
              sx={{
                backgroundColor: "#e0e0e0",
                borderRadius: "10px",
                height: "40vh",
                minHeight: "385px",
              }}
            >
              <Stack
                direction="row"
                sx={{ display: "flex", alignItems: "center", marginTop: "1rem", marginLeft: "1rem" }}
              >
                <Typography variant="h5">My Accounts</Typography>
                <IconButton
                  id="add-button"
                  aria-controls={isAddMenuOpened ? "add-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={isAddMenuOpened ? "true" : undefined}
                  onClick={handleAddMenuOpened}
                  disabled={!ready}
                  sx={{ marginLeft: "5px" }}
                >
                  <AddCircleIcon color="primary" fontSize="inherit" />
                </IconButton>
                <Menu
                  id="add-menu"
                  anchorEl={addMenuAnchorEl}
                  open={isAddMenuOpened}
                  onClose={handleAddMenuClosed}
                  MenuListProps={{
                    "aria-labelledby": "add-button",
                  }}
                >
                  <MenuItem onClick={handleAddBankAccountClicked}>Add Bank Account</MenuItem>
                  <MenuItem onClick={handleAddCustomAssetClicked}>Add Custom Asset</MenuItem>
                </Menu>
                {isAccountAddLoading && (
                  <Tooltip title="Adding account in process please wait...">
                    <CircularProgress size={20} sx={{ marginLeft: "10px" }} />
                  </Tooltip>
                )}
              </Stack>
              <Box
                sx={{
                  height: "80%",
                  margin: "1rem",
                  overflowY: "scroll",
                  marginRight: "5px",
                  "::-webkit-scrollbar": { WebkitAppearance: "none", width: "7px" },
                  "::-webkit-scrollbar-thumb": {
                    borderRadius: "10px",
                    backgroundColor: "#115247",
                    WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, .5)",
                  },
                }}
              >
                {isItemsLoading ? (
                  <CircularProgress />
                ) : fetchItemsErrorMessage !== "" ? (
                  <Alert severity="error" variant="filled" sx={{ marginBottom: "20px" }}>
                    {fetchItemsErrorMessage}
                  </Alert>
                ) : (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h6">Cash ({cashAccounts.length})</Typography>
                    </Grid>
                    {renderAccounts(cashAccounts)}
                    <Grid item xs={12}>
                      <Typography variant="h6">Credit ({creditAccounts.length})</Typography>
                    </Grid>
                    {renderAccounts(creditAccounts)}
                    <Grid item xs={12}>
                      <Typography variant="h6">Loans ({loanAccounts.length})</Typography>
                    </Grid>
                    {renderAccounts(loanAccounts)}
                    <Grid item xs={12}>
                      <Typography variant="h6">Investments ({investmentAccounts.length})</Typography>
                    </Grid>
                    {renderAccounts(investmentAccounts)}
                    <Grid item xs={12}>
                      <Typography variant="h6">Custom Assets ({customAssetAccounts.length})</Typography>
                    </Grid>
                    {renderCustomAssets()}
                    {noAccounts.length > 0 && (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Linked Institutions with No Accounts ({noAccounts.length})
                          </Typography>
                        </Grid>
                        {renderAccounts(noAccounts)}
                      </>
                    )}
                  </Grid>
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
        {/* My Transactions Paper */}
        <Card
          sx={{
            backgroundColor: "#e0e0e0",
            marginTop: "1rem",
            borderRadius: "10px",
            "&:before": {
              display: "none",
            },
            "&:last-of-type": {
              borderRadius: "10px",
            },
            padding: "1rem",
          }}
        >
          <Typography variant="h5" sx={{ paddingBottom: "1rem", display: "inline-block" }}>
            My Transactions
          </Typography>
          {!isTransactionsLoading && !isTransactionSearchActive && (
            <Pagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              shape="rounded"
              onChange={(event, page) => {
                currentPageRef.current = page;
                setCurrentPage(page);
              }}
              renderItem={(item) => {
                if (item.type === "next") {
                  return (
                    <PaginationItem
                      {...item}
                      disabled={
                        currentPageRef.current === totalPageRef.current ? transactionLastEvaluatedKey === null : false
                      }
                      onClick={
                        currentPageRef.current === totalPageRef.current ? handleNextPageButtonClicked : item.onClick
                      }
                      size="large"
                      sx={{ margin: "0px" }}
                    />
                  );
                }
                return <PaginationItem {...item} size="large" />;
              }}
              sx={{ float: "right" }}
            />
          )}
          <TransactionSearchInput
            isDesktopScreenSize={props.isDesktopScreenSize}
            transactions={transactions}
            setTransactions={setTransactions}
            setIsTransactionsLoading={setIsTransactionsLoading}
            fetchTransactionsByUser={fetchTransactionsByUser}
            setIsTransactionSearchActive={setIsTransactionSearchActive}
            isTransactionSearchActive={isTransactionSearchActive}
            isTransactionsLoading={isTransactionsLoading}
            resetPagination={handleTransactionSearchResetPagination}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            currentPageRef={currentPageRef}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            totalPageRef={totalPageRef}
          />
          {isTransactionsLoading ? (
            <LinearProgress />
          ) : fetchTransactionsErrorMessage !== "" ? (
            <Alert severity="error" variant="filled" sx={{ marginBottom: "20px" }}>
              {fetchTransactionsErrorMessage}
            </Alert>
          ) : (
            <Box sx={{ marginTop: "10px" }}>{renderTransactions()}</Box>
          )}
          {!isTransactionsLoading && !isTransactionSearchActive && (
            <Pagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              shape="rounded"
              onChange={(event, page) => {
                currentPageRef.current = page;
                setCurrentPage(page);
              }}
              renderItem={(item) => {
                if (item.type === "next") {
                  return (
                    <PaginationItem
                      {...item}
                      disabled={
                        currentPageRef.current === totalPageRef.current ? transactionLastEvaluatedKey === null : false
                      }
                      onClick={
                        currentPageRef.current === totalPageRef.current ? handleNextPageButtonClicked : item.onClick
                      }
                      size="large"
                    />
                  );
                }
                return <PaginationItem {...item} size="large" />;
              }}
              sx={{ float: "right" }}
            />
          )}
        </Card>
        <Box sx={{ height: "50px" }}></Box>
        {isCategorySettingsDialogOpen && (
          <CategorySettingsDialog
            isDialogOpen={isCategorySettingsDialogOpen}
            handleDialogClosed={() => {
              fetchTransactionsByUser(userId, accessToken);
              setIsCategorySettingsDialogOpen(false);
            }}
            isDesktopScreenSize={props.isDesktopScreenSize}
          />
        )}
        {isAddCustomAssetDialogOpen && (
          <CustomAssetDialog
            isDialogOpen={isAddCustomAssetDialogOpen}
            setIsDialogOpen={setIsAddCustomAssetDialogOpen}
            onUpdateCustomAsset={fetchCustomAssetsByUser}
            isDesktopScreenSize={props.isDesktopScreenSize}
          />
        )}
        {mainErrorMessage !== "" && (
          <Dialog open={mainErrorMessage !== ""}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent sx={{ width: "30vw" }}>
              <Alert severity="error">{mainErrorMessage}</Alert>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleCloseMainError}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    );
  };

  const renderNoAccountsLinkedScreen = () => {
    return (
      <Box sx={{ height: "100vh", textAlign: "center", backgroundColor: "secondary.dark" }}>
        <Typography variant="h6" sx={{ margin: "2rem", color: "secondary.main" }}>
          finwatch isn't really fun without connecting your banks. Go ahead and add one!
        </Typography>
        <Button
          size="large"
          variant="contained"
          onClick={() => open()}
          disabled={!ready || isAccountAddLoading}
          sx={{ width: "200px" }}
        >
          {isAccountAddLoading ? <CircularProgress /> : "Add Account"}
        </Button>
      </Box>
    );
  };

  const renderAuthenticatedHomePage = () => {
    return (
      <Box sx={{ backgroundColor: "secondary.dark" }}>
        {isItemsLoading || !userConfig
          ? renderLoadingScreen()
          : items.length > 0
          ? renderMainScreen()
          : renderNoAccountsLinkedScreen()}
        <SyncSnackbar
          lastAccountBalanceSync={userConfig?.lastAccountBalanceSync}
          doesAccountHaveItems={items.length > 0}
          fetchItemsByUser={fetchItemsByUser}
          isNewItemAdded={isNewItemAdded}
          setIsNewItemAdded={setIsNewItemAdded}
        />
      </Box>
    );
  };

  return <Fragment>{isAuthenticated() && renderAuthenticatedHomePage()}</Fragment>;
};

Home.propTypes = {
  isDesktopScreenSize: PropTypes.bool.isRequired,
};

export default Home;
