const institutionIdToLogoMap = Object.freeze({
  ins_25: "https://i.imgur.com/dlWRoFB.png", // Ally
  ins_56: "https://i.imgur.com/vucXJnh.jpg", // Chase
  ins_116276: "https://i.imgur.com/9tG03ct.png", // SWACU
  ins_132083: "https://i.imgur.com/Vk0jTtT.png", // Venmo
  ins_129572: "https://i.imgur.com/AQm5vPe.jpg", // SWA Empower Retirement
  ins_116552: "https://i.imgur.com/AQm5vPe.jpg", // SWA Empower Retirement (new)
  ins_11: "https://i.imgur.com/vfl4vSq.png", // Schwab
});

export const getInstitutionLogo = (id) => {
  return institutionIdToLogoMap[id];
};

const institutionIdToBankNameMap = Object.freeze({
  ins_25: "Ally", // Ally
  ins_56: "Chase", // Chase
  ins_116276: "SWACU", // SWACU
  ins_132083: "Venmo", // Venmo
  ins_129572: "SWA Empower Retirement", // SWA Empower Retirement
  ins_116552: "SWA Empower Retirement", // SWA Empower Retirement (new)
  ins_11: "Schwab", // Schwab
});

export const getInstitutionBankName = (id) => {
  return institutionIdToBankNameMap[id];
};
