import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  autocompleteClasses,
  createFilterOptions,
} from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { updateTransaction } from "../api/budget";
import { getTransactionCategory } from "../utils/common";
import { formatIntToMoneyAbsolute } from "../utils/customFormats";
import TransactionEditDialog from "./TransactionEditDialog";
import { useCategory } from "./category/CategoryProvider";
import { useAuth } from "./auth/AuthProvider";

const Transaction = (props) => {
  const { accessToken, userId } = useAuth();
  const { pkToCategoryMap, mainFriendlyNameToCategoryMap } = useCategory();

  const [name, setName] = useState(
    props.transaction?.merchant_name ? props.transaction?.merchant_name : props.transaction?.name
  );
  const [date, setDate] = useState(
    props.transaction.authorized_date ? props.transaction.authorized_date : props.transaction.date
  );
  const [isRead, setIsRead] = useState(props.transaction.isRead);
  const [category, setCategory] = useState(null);
  const [categoryInputValue, setCategoryInputValue] = useState("");
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  useEffect(() => {
    setIsRead(props.transaction.isRead);
  }, [props]);

  useEffect(() => {
    let category = getTransactionCategory(pkToCategoryMap, mainFriendlyNameToCategoryMap, props.transaction);
    setCategory(category);
    setCategoryInputValue(category?.subFriendlyName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pkToCategoryMap, mainFriendlyNameToCategoryMap]);

  const categoryFilterOptions = createFilterOptions({
    stringify: (option) => {
      if (option.label === "New Category") {
        return categoryInputValue;
      } else {
        return `${option.mainFriendlyName}${option.subFriendlyName}`;
      }
    },
  });

  const handleReadButtonClicked = () => {
    setIsRead(!isRead);
    props.onReadTransaction(props.transaction);
  };

  const handleEditButtonClicked = () => {
    setIsEditDialogOpen(true);
  };

  const handleEditDialogClosed = () => {
    setIsEditDialogOpen(false);
  };

  const handleCategoryChanged = (event) => {
    setIsRead(true);
    props.onReadTransaction(props.transaction, true);
    setCategory(event);
    updateTransaction(
      userId,
      props.transaction.transaction_id,
      JSON.stringify({ appCategory: { pk: event.pk, sk: event.sk } }),
      accessToken
    );
  };

  const renderDesktopVersion = () => {
    return (
      <Paper
        elevation={1}
        sx={{
          margin: "5px",
          padding: "10px",
          borderRadius: "5px",
          color: "secondary.main",
          backgroundColor: isRead
            ? props.idx % 2 === 0
              ? "secondary.main"
              : "secondary.grey"
            : props.idx % 2 === 0
            ? "primary.main"
            : "primary.dark",
          opacity: props.transaction.pending ? "60%" : "100%",
          ":hover": {
            borderInline: "5px solid white",
            borderRadius: "5px",
          },
        }}
      >
        <Grid container>
          <Grid item xs={12} md={9}>
            <Stack direction="row">
              <Avatar
                alt="txn-logo"
                src={props.transaction.logo_url}
                sx={{
                  height: props.isDesktopScreenSize ? "45px" : "20px",
                  width: props.isDesktopScreenSize ? "45px" : "20px",
                  border: "5px solid white",
                }}
              />
              <Box ml={2} sx={{ color: isRead ? "common.black" : "secondary.main" }}>
                <Typography
                  variant={props.isDesktopScreenSize ? "body1" : "body2"}
                  sx={{ width: "30vw", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {name}
                  {props.transaction.pending && (
                    <Chip
                      component="span"
                      label="PENDING"
                      size="small"
                      sx={{ marginLeft: "1rem", backgroundColor: "secondary.dark" }}
                    />
                  )}
                </Typography>
                <Typography variant={props.isDesktopScreenSize ? "body1" : "caption"} sx={{ fontWeight: "bold" }}>
                  {props.account?.name}
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Stack direction="row" sx={{ justifyContent: "end" }}>
              <Box>
                {category && (
                  <Autocomplete
                    value={category?.subFriendlyName}
                    onChange={(event, newValue) => handleCategoryChanged(newValue)}
                    inputValue={categoryInputValue}
                    onInputChange={(event, newValue) => setCategoryInputValue(newValue)}
                    options={[
                      ...Array.from(pkToCategoryMap?.values())
                        .sort((a, b) => {
                          if (a.mainFriendlyName < b.mainFriendlyName) return -1;
                          if (a.mainFriendlyName > b.mainFriendlyName) return 1;
                          if (a.subFriendlyName < b.subFriendlyName) return -1;
                          if (a.subFriendlyName > b.subFriendlyName) return 1;
                          return 0;
                        })
                        .map((category) => {
                          return { label: category?.subFriendlyName, ...category };
                        }),
                      { label: "New Category", mainFriendlyName: "New Category" },
                    ]}
                    isOptionEqualToValue={(option, value) => option.subFriendlyName === value}
                    groupBy={(option) => option.mainFriendlyName}
                    filterOptions={categoryFilterOptions}
                    renderGroup={(params) => {
                      if (params.group === "New Category") {
                        return (
                          <Button
                            key={params.key}
                            startIcon={<AddIcon />}
                            variant="contained"
                            sx={{
                              alignItems: "center",
                              width: "95%",
                              margin: "5px",
                              padding: "12px",
                              backgroundColor: "primary.light",
                              color: "secondary.main",
                              borderRadius: "10px",
                            }}
                            onClick={() => props.setIsCategorySettingsDialogOpen(true)}
                          >
                            {params.group}
                          </Button>
                        );
                      } else {
                        return (
                          <Box key={params.key}>
                            <Typography
                              sx={{
                                position: "sticky",
                                top: "-8px",
                                padding: "10px 10px",
                                marginLeft: "5px",
                                marginRight: "5px",
                                backgroundColor: "primary.main",
                                color: "secondary.main",
                                borderRadius: "10px 10px 0px 0px",
                              }}
                            >
                              {params.group}
                            </Typography>
                            <Typography>{params.children}</Typography>
                          </Box>
                        );
                      }
                    }}
                    renderOption={(props, option, state, ownerState) => {
                      const label = props.key;
                      if (label === "New Category") {
                        return "";
                      } else {
                        const { key, ...optionProps } = props;
                        return (
                          <Box
                            key={key}
                            sx={{
                              borderRadius: "10px",
                              margin: "10px",
                              [`&.${autocompleteClasses.option}`]: {
                                padding: "12px",
                              },
                            }}
                            component="li"
                            {...optionProps}
                          >
                            {ownerState.getOptionLabel(option)}
                          </Box>
                        );
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Category"
                          variant="filled"
                          sx={{
                            borderTopLeftRadius: "7px",
                            borderTopRightRadius: "7px",
                          }}
                        />
                      );
                    }}
                    sx={{
                      marginRight: "1rem",
                      minWidth: "200px",
                      width: "20vw",
                      backgroundColor: "secondary.main",
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                    }}
                    disableClearable
                  ></Autocomplete>
                )}
              </Box>

              <Box sx={{ textAlign: "right" }}>
                <Typography variant="body2" sx={{ color: isRead ? "common.black" : "secondary.main" }}>
                  {dayjs(date).format("MM/DD/YYYY")}
                </Typography>
                <Chip
                  label={formatIntToMoneyAbsolute(props.transaction.amount)}
                  icon={
                    props.transaction.amount > 0 ? (
                      <RemoveCircleOutlineIcon color="secondary" sx={{ color: "money.negative" }} />
                    ) : (
                      <AddCircleOutlineIcon color="secondary" sx={{ color: "money.positive" }} />
                    )
                  }
                  sx={{
                    color: props.transaction.amount > 0 ? "money.negative" : "money.positive",
                    backgroundColor: "#444140",
                    width: "150px",
                    justifyContent: "start",
                    borderRadius: "5px",
                    fontSize: "20px",
                  }}
                />
              </Box>
              <IconButton
                aria-label="read"
                size="large"
                sx={{ height: "50px", width: "50px", marginLeft: "10px" }}
                onClick={handleEditButtonClicked}
              >
                <EditIcon sx={{ color: isRead ? "common.black" : "secondary.main" }} />
              </IconButton>
              <IconButton
                aria-label="read"
                size="large"
                sx={{ height: "50px", width: "50px" }}
                onClick={handleReadButtonClicked}
              >
                <LibraryBooksIcon sx={{ color: isRead ? "common.black" : "secondary.main" }} />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const renderMobileVersion = () => {
    return (
      <Paper
        elevation={1}
        sx={{
          margin: "5px",
          padding: "10px",
          paddingBottom: "5px",
          borderRadius: "10px",
          color: "secondary.main",
          backgroundColor: isRead ? "secondary.main" : "primary.main",
          opacity: props.transaction.pending ? "60%" : "100%",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row">
              <Box sx={{ color: isRead ? "common.black" : "secondary.main" }}>
                <Typography variant={props.isDesktopScreenSize ? "body1" : "body2"} sx={{ width: "40vw" }}>
                  {name}
                  {props.transaction.pending && (
                    <Chip
                      component="span"
                      label="PENDING"
                      size="small"
                      sx={{
                        color: "secondary.main",
                        backgroundColor: "secondary.dark",
                        display: "flex",
                        width: "80px",
                      }}
                    />
                  )}
                </Typography>
                <Typography variant={props.isDesktopScreenSize ? "body1" : "caption"} sx={{ fontWeight: "bold" }}>
                  {props.account?.name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", width: "100%", justifyContent: "end" }}>
                <Stack direction="column">
                  <Typography
                    variant="body2"
                    sx={{ color: isRead ? "common.black" : "secondary.main", textAlign: "right" }}
                  >
                    {dayjs(date).format("MM/DD/YYYY")}
                  </Typography>
                  <Chip
                    label={formatIntToMoneyAbsolute(props.transaction.amount)}
                    icon={
                      props.transaction.amount > 0 ? (
                        <RemoveCircleOutlineIcon color="secondary" sx={{ color: "money.negative" }} />
                      ) : (
                        <AddCircleOutlineIcon color="secondary" sx={{ color: "money.positive" }} />
                      )
                    }
                    sx={{
                      color: props.transaction.amount > 0 ? "money.negative" : "money.positive",
                      backgroundColor: "#444140",
                      width: "120px",
                      justifyContent: "start",
                      borderRadius: "5px",
                      fontSize: "0.875rem",
                      marginTop: "5px",
                      display: "flex",
                    }}
                  />
                </Stack>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "5px" }}>
            <Stack direction="row" sx={{ justifyContent: "end" }}>
              <Box sx={{ width: "100%" }}>
                {category && (
                  <Autocomplete
                    value={category?.subFriendlyName}
                    onChange={(event, newValue) => handleCategoryChanged(newValue)}
                    inputValue={categoryInputValue}
                    onInputChange={(event, newValue) => setCategoryInputValue(newValue)}
                    options={[
                      ...Array.from(pkToCategoryMap?.values())
                        .sort((a, b) => {
                          if (a.mainFriendlyName < b.mainFriendlyName) return -1;
                          if (a.mainFriendlyName > b.mainFriendlyName) return 1;
                          if (a.subFriendlyName < b.subFriendlyName) return -1;
                          if (a.subFriendlyName > b.subFriendlyName) return 1;
                          return 0;
                        })
                        .map((category) => {
                          return { label: category?.subFriendlyName, ...category };
                        }),
                      { label: "New Category", mainFriendlyName: "New Category" },
                    ]}
                    isOptionEqualToValue={(option, value) => option.subFriendlyName === value}
                    groupBy={(option) => option.mainFriendlyName}
                    filterOptions={categoryFilterOptions}
                    ListboxProps={{ sx: { position: "absolute", backgroundColor: "secondary.main" } }}
                    renderGroup={(params) => {
                      if (params.group === "New Category") {
                        return (
                          <Button
                            key={params.key}
                            startIcon={<AddIcon />}
                            variant="contained"
                            sx={{
                              alignItems: "center",
                              width: "95%",
                              margin: "5px",
                              padding: "12px",
                              backgroundColor: "primary.light",
                              color: "secondary.main",
                              borderRadius: "10px",
                            }}
                            onClick={() => props.setIsCategorySettingsDialogOpen(true)}
                          >
                            {params.group}
                          </Button>
                        );
                      } else {
                        return (
                          <Box key={params.key}>
                            <Typography
                              sx={{
                                position: "sticky",
                                top: "-8px",
                                padding: "10px 10px",
                                marginLeft: "5px",
                                marginRight: "5px",
                                backgroundColor: "primary.main",
                                color: "secondary.main",
                                borderRadius: "10px 10px 0px 0px",
                              }}
                            >
                              {params.group}
                            </Typography>
                            <Typography>{params.children}</Typography>
                          </Box>
                        );
                      }
                    }}
                    renderOption={(props, option, state, ownerState) => {
                      const label = props.key;
                      if (label === "New Category") {
                        return "";
                      } else {
                        const { key, ...optionProps } = props;
                        return (
                          <Box
                            key={key}
                            sx={{
                              borderRadius: "10px",
                              margin: "10px",
                              [`&.${autocompleteClasses.option}`]: {
                                padding: "12px",
                              },
                            }}
                            component="li"
                            {...optionProps}
                          >
                            {ownerState.getOptionLabel(option)}
                          </Box>
                        );
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Category"
                          variant="filled"
                          sx={{
                            borderTopLeftRadius: "7px",
                            borderTopRightRadius: "7px",
                          }}
                        />
                      );
                    }}
                    sx={{
                      width: "100%",
                      backgroundColor: "secondary.main",
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                    }}
                    disableClearable
                  ></Autocomplete>
                )}
              </Box>

              <IconButton
                aria-label="read"
                size="large"
                sx={{ height: "50px", width: "50px", marginLeft: "10px" }}
                onClick={handleEditButtonClicked}
              >
                <EditIcon sx={{ color: isRead ? "common.black" : "secondary.main" }} />
              </IconButton>
              <IconButton
                aria-label="read"
                size="large"
                sx={{ height: "50px", width: "50px" }}
                onClick={handleReadButtonClicked}
              >
                <LibraryBooksIcon sx={{ color: isRead ? "common.black" : "secondary.main" }} />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return (
    <>
      {pkToCategoryMap &&
        mainFriendlyNameToCategoryMap &&
        (props.isDesktopScreenSize ? renderDesktopVersion() : renderMobileVersion())}

      {isEditDialogOpen && (
        <TransactionEditDialog
          transaction={props.transaction}
          isEditDialogOpen={isEditDialogOpen}
          setIsEditDialogOpen={setIsEditDialogOpen}
          handleEditDialogClosed={handleEditDialogClosed}
          setName={setName}
          setDate={setDate}
          isDesktopScreenSize={props.isDesktopScreenSize}
        />
      )}
    </>
  );
};

Transaction.propTypes = {
  idx: PropTypes.number.isRequired,
  transaction: PropTypes.object.isRequired,
  onReadTransaction: PropTypes.func.isRequired,
  account: PropTypes.object,
  isDesktopScreenSize: PropTypes.bool.isRequired,
  setIsCategorySettingsDialogOpen: PropTypes.func.isRequired,
};

export default Transaction;
