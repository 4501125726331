import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { getAccessToken, getUserId, logOut } from "../../api/auth/auth";
import { APP_AUTHENTICATED_PATHS, APP_PATHS } from "../../utils/constants";
import { useHistory } from "react-router-dom";

const AuthContext = createContext(null);

export const AuthProvider = (props) => {
  const history = useHistory();

  const [accessToken, setAccessToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const isClearSessionLoadingRef = useRef(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isAuthenticated = () => {
    return !!accessToken && !!userId;
  };

  useEffect(() => {
    if (!isAuthenticated() && isAuthPage()) {
      loadSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAuthPage = () => {
    for (const path of Object.values(APP_AUTHENTICATED_PATHS)) {
      if (window.location.pathname.includes(path)) {
        console.log("true");
        return true;
      }
    }
    return false;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadSession = async () => {
    getAccessToken()
      .then((response) => {
        console.log("hi");
        if (response.accessToken) {
          setAccessToken(response.accessToken);
          setUserId(getUserId(response.accessToken));
        }
      })
      .catch(async (error) => {
        // console.log("Token error, clearing session.");
        await clearSession();
        history.push(APP_PATHS.LOG_IN, { error: "Your session expired. Please log in again." });
      });
    console.log("hi");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clearSession = async () => {
    if (!isClearSessionLoadingRef.current) {
      isClearSessionLoadingRef.current = true;
      await logOut();
      setAccessToken(null);
      isClearSessionLoadingRef.current = false;
    }
  };

  const authProviderValue = useMemo(
    () => ({ accessToken, userId, clearSession, isAuthenticated, loadSession }),
    [accessToken, userId, clearSession, isAuthenticated, loadSession]
  );

  return <AuthContext.Provider value={authProviderValue}>{props.children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export const useAuth = () => {
  return useContext(AuthContext);
};
