import { Alert, Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { confirmSignUp, resendConfirmationCode } from "../../api/auth/cognito";
import finwatch_logo from "../../assets/finwatch_app_icon_white_border.png";
import { APP_PATHS } from "../../utils/constants";

export const ConfirmSignUp = (props) => {
  const location = useLocation();
  const history = useHistory();
  // Page State
  const [code, setCode] = useState("");
  const [errors, setErrors] = useState({ code: "" });
  const [signUpConfirmSubmissionError, setSignUpConfirmSubmissionError] = useState("");
  const [isSignUpConfirmSubmissionLoading, setIsSignUpConfirmSubmissionLoading] = useState(false);
  const [isResendConfirmationCodeLoading, setIsResendConfirmationCodeLoading] = useState(false);

  const validateCode = (code) => {
    const regex = /^\d{6}$/;
    const isValid = regex.test(code);
    setErrors((prevErrors) => ({
      ...prevErrors,
      code: isValid ? "" : "Code input is not valid",
    }));
    return isValid;
  };

  const handleSignUpConfirmClicked = () => {
    const isCodeValid = validateCode(code);
    const isEmailAvailable = location?.state?.email;

    if (isCodeValid && isEmailAvailable) {
      setIsSignUpConfirmSubmissionLoading(true);
      console.log("Confirm Sign-up data is valid. Proceeding with confirm sign-up...");
      confirmSignUp({ email: location.state.email, code: code }).then((response) => {
        console.log("response: ", response);
        if (response.error) {
          setSignUpConfirmSubmissionError(response.error);
          setIsSignUpConfirmSubmissionLoading(false);
        } else {
          console.log("Success!");
          setIsSignUpConfirmSubmissionLoading(false);
          history.push(APP_PATHS.LOG_IN, { email: location.state.email });
        }
      });
    } else {
      history.push(APP_PATHS.LOG_IN, {
        error: "Something went wrong with your email confirmation. Please log in again.",
      });
    }
  };

  const handleResendCodeClicked = () => {
    const isEmailAvailable = location?.state?.email;

    if (isEmailAvailable) {
      setIsResendConfirmationCodeLoading(true);
      resendConfirmationCode({ email: location.state.email }).then((response) => {
        if (response.error) {
          setSignUpConfirmSubmissionError(response.error);
          setIsResendConfirmationCodeLoading(false);
        } else {
          setIsResendConfirmationCodeLoading(false);
        }
      });
    } else {
      history.push(APP_PATHS.LOG_IN, {
        error: "Something went wrong with your email confirmation. Please log in again.",
      });
    }
  };

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Stack
        direction="column"
        sx={{
          margin: "auto",
          justifyContent: "center",
          height: "100vh",
          width: "300px",
          maxWidth: "90vw",
          color: "secondary.main",
        }}
      >
        <Box
          component="img"
          sx={{
            margin: "10px auto",
            width: props.isDesktopScreenSize ? "130px" : "75px",
          }}
          alt="Logo"
          src={finwatch_logo}
        />
        <Typography variant="h3" sx={{ fontFamily: "Noto Serif Balinese" }} textAlign="center">
          finwatch
        </Typography>
        <Typography textAlign="center" margin="10px">
          Check your email for a 6-digit confirmation code.
        </Typography>
        <MuiOtpInput
          length={6}
          value={code}
          onChange={(event) => setCode(event)}
          gap={1}
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        {errors.code !== "" && (
          <Alert severity="error" variant="outlined">
            {errors.code}
          </Alert>
        )}
        {signUpConfirmSubmissionError !== "" && (
          <Alert severity="error" variant="filled" sx={{ marginTop: "10px", marginBottom: "20px" }}>
            {signUpConfirmSubmissionError}
          </Alert>
        )}
        <Box sx={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSignUpConfirmClicked}
            color="primary"
            type="submit"
            sx={{
              backgroundColor: "primary.dark",
              margin: "auto",
              marginTop: "10px",
              height: "50px",
            }}
            disabled={isSignUpConfirmSubmissionLoading}
          >
            {isSignUpConfirmSubmissionLoading ? <CircularProgress /> : "Confirm"}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleResendCodeClicked}
            sx={{
              color: "primary.main",
              backgroundColor: "secondary.main",
              ":hover": {
                color: "secondary.main",
                backgroundColor: "secondary.dark",
              },
              marginTop: "10px",
              marginBottom: "10px",
              height: "50px",
            }}
          >
            {isResendConfirmationCodeLoading ? <CircularProgress /> : "Resend Code"}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

ConfirmSignUp.propTypes = {
  isDesktopScreenSize: PropTypes.bool.isRequired,
};

export default ConfirmSignUp;
