import React, { Fragment } from "react";

import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import discord_button from "../assets/Discord_button.png";
import finwatch_logo from "../assets/finwatch_app_icon_white_border.png";
import { APP_PATHS } from "../utils/constants";

export const Trends = (props) => {
  const history = useHistory();

  const handleSignUpClicked = () => {
    history.push(APP_PATHS.SIGN_UP, {});
  };

  const handleLogInClicked = () => {
    history.push(APP_PATHS.LOG_IN, {});
  };

  const renderButtons = () => {
    return (
      <Grid item md={5} sm={12} sx={{ display: "flex", justifyContent: "center", marginTop: "50px", margin: "auto" }}>
        <Stack direction="column">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSignUpClicked}
            sx={{
              display: "flex",
              margin: "auto",
              marginTop: "20px",
              width: "200px",
              height: "40px",
              backgroundColor: "primary.dark",
            }}
          >
            Sign Up
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleLogInClicked}
            sx={{ display: "flex", margin: "auto", marginTop: "20px", width: "200px" }}
          >
            Log In
          </Button>
          <a href="https://discord.gg/7jHU9ju3xs" style={{ textDecoration: "none", color: "inherit" }}>
            <Box
              component="img"
              sx={{
                display: "flex",
                width: "200px",
                margin: "10px auto",
                marginTop: "20px",
                borderRadius: "5px",
              }}
              alt="discord button"
              src={discord_button}
            />
          </a>
        </Stack>
      </Grid>
    );
  };

  const renderAboutPage = () => {
    return (
      <Grid
        container
        sx={{
          height: "100vh",
          color: "secondary.main",
          overflowY: "scroll",
        }}
      >
        <Grid item md={2} sm={12} sx={{ marginTop: "20px", margin: "auto" }}>
          <Stack direction="column">
            <Box
              component="img"
              sx={{
                margin: "10px auto",
                width: "110px",
              }}
              alt="Logo"
              src={finwatch_logo}
            />
            <Typography variant="h3" sx={{ fontFamily: "Noto Serif Balinese" }} textAlign="center">
              finwatch
            </Typography>
            {props.isDesktopScreenSize && renderButtons()}
          </Stack>
        </Grid>
        <Grid item md={5} sm={12} sx={{ display: "flex", justifyContent: "center", marginTop: "50px", margin: "auto" }}>
          <Typography
            component={"span"}
            sx={{
              fontFamily: "Raleway",
              width: props.isDesktopScreenSize ? "40vw" : "90vw",
            }}
          >
            Hi there! My name is George. <br />
            When Mint was shutdown, I needed to find an alternative to monitor my finances. During my search, I learned
            about a service called{" "}
            <Typography
              component="a"
              href="https://plaid.com/"
              sx={{
                textDecoration: "underline",
                color: "primary.dark",
                ":hover": { cursor: "pointer", color: "secondary.main" },
              }}
            >
              Plaid
            </Typography>
            . With the power of Plaid, I realized I could build my own financial monitoring application, bringing all
            the features I loved about Mint and adding in features I always wish it had.
            <br />
            <br />
            Introducing, finwatch :)
            <br />
            <br />
            This is a <strong>very early stage build</strong>. It does just enough to allow me to track my own finances
            and has had almost no testing outside of that. With that in mind there is a high likelihood that you will
            run into bugs here and there. My goal is to build and improve upon this application with a community of
            like-minded folks who enjoy tracking their finances and want an opportunity to provide highly specific
            feedback to transform finwatch into the best financial monitoring application for us all!
            <br />
            <br />
            One serious problem is I know nothing about how to make an application profitable, and I can't exactly
            afford to pay for each user's Plaid connections in addition to general cloud costs.
            <br />
            <br />
            What this means is, as a user of finwatch, you'll have to pay monthly for your own Plaid institution(bank)
            connections at $0.30/institution plus a $1 fee for general cloud costs. To give you an idea, I am currently
            paying $3.40/month to cover my own portfolio.
            <br />
            <br />
            To get the ball moving for finwatch, at first I want to commit to supporting the first 20 users for{" "}
            <strong>FREE</strong>. This number will grow as the community grows. If you want to be one of those users,
            then join the Discord and you'll find out how!
            <br />
            <br />
            The Discord is the HEART of the community. It's where you can provide detailed feedback, brainstorm with
            like-minded community members, and get all the latest finwatch news. Quick, join it!
            {/* my favorite part being that I no longer worry about where I left off in my transaction list. */}
          </Typography>
        </Grid>
        {!props.isDesktopScreenSize && renderButtons()}
        <Grid item md={5} sm={12} sx={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
          Screenshots/gifs to go here to showcase main features and app-feel
          <br />
          - Read receipts on transactions
          <br />
          - Sort your transactions into customizable categories
          <br />
          - Review your financial trends month-over-month
          <br />- +More with YOUR help!
        </Grid>
      </Grid>
    );
  };

  return <Fragment>{renderAboutPage()}</Fragment>;
};

Trends.propTypes = {
  isDesktopScreenSize: PropTypes.bool.isRequired,
};

export default Trends;
