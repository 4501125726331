import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "../auth/AuthProvider";

const WebSocketContext = createContext(null);

export const WebSocketProvider = (props) => {
  const { accessToken, userId, isAuthenticated } = useAuth();

  const [ws, setWs] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (isAuthenticated()) {
      const socket = new WebSocket(
        process.env.REACT_APP_WS_ENDPOINT + "?userId=" + userId + "&token=Bearer " + accessToken
      );
      setWs(socket);

      socket.onmessage = (event) => {
        setMessages((prevMessages) => [...prevMessages, event.data]);
      };

      socket.onopen = (event) => {
        console.log("Websocket Connection is open: %j", event);
      };

      socket.onclose = (event) => {
        console.log("Websocket Connection is closed: %j", event);
        console.log("Attempting reconnect...");
        setWs(null);
      };

      socket.onerror = (event) => {
        console.log("Websocket Connection had an error: %j", event);
      };

      return () => {
        socket.close();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeWebsocketConnection = () => {
    ws?.close();
    setWs(null);
    setMessages([]);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getNextMessage = () => {
    if (messages.length > 0) {
      const nextMessage = messages[0];
      removeFirstMessage();
      return JSON.parse(nextMessage);
    }
    return null;
  };

  const removeFirstMessage = () => {
    setMessages((prevMessages) => {
      const newMessageList = [...prevMessages];
      newMessageList.shift();
      return newMessageList;
    });
  };

  const websocketProviderValue = useMemo(
    () => ({ ws, messages, closeWebsocketConnection, getNextMessage }),
    [ws, messages, closeWebsocketConnection, getNextMessage]
  );

  return <WebSocketContext.Provider value={websocketProviderValue}>{props.children}</WebSocketContext.Provider>;
};

WebSocketProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
