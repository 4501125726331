import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { UNCATEGORIZED } from "../../utils/constants";
import AddMainCategoryButton from "./AddMainCategoryButton";
import AddSubCategoryButton from "./AddSubCategoryButton";
import CategoryChip from "./CategoryChip";
import { useCategory } from "./CategoryProvider";
import { resetUserCategories } from "../../api/category";
import { useAuth } from "../auth/AuthProvider";

const CategorySettingsDialog = (props) => {
  const { accessToken, userId } = useAuth();
  const { mainFriendlyNameToCategoryMap, categoryError, fetchCategoriesByUser } = useCategory();
  const [isAreYouSureYouWantToResetToDefaultDialogOpen, setIsAreYouSureYouWantToResetToDefaultDialogOpen] =
    useState("");
  const [isResetToDefaultLoading, setIsResetToDefaultLoading] = useState(false);
  const [isResetToDefaultSuccess, setIsResetToDefaultSuccess] = useState(false);
  const [isResetToDefaultError, setIsResetToDefaultError] = useState(false);

  const handleResetToDefaultClicked = () => {
    setIsResetToDefaultLoading(true);
    setIsResetToDefaultError(false);
    resetUserCategories(userId, accessToken)
      .then((response) => {
        console.log(response);
        setIsResetToDefaultSuccess(true);
        fetchCategoriesByUser();
        setIsAreYouSureYouWantToResetToDefaultDialogOpen(false);
      })
      .catch((e) => {
        setIsResetToDefaultError(true);
      })
      .finally(() => {
        setIsResetToDefaultLoading(false);
      });
  };

  const renderMainCategory = (categoryMainFriendlyName) => {
    return (
      <Box key={categoryMainFriendlyName}>
        <Typography
          sx={
            props.isDesktopScreenSize
              ? {
                  fontWeight: "bold",
                  borderRadius: "5px 5px 0px 0px",
                  margin: "auto 5px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  padding: "10px",
                  width: "95%",
                  backgroundColor: "primary.main",
                  color: "secondary.main",
                  alignContent: "center",
                }
              : {
                  fontWeight: "bold",
                  borderRadius: "5px 5px 0px 0px",
                  margin: "auto 5px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  padding: "10px",
                  backgroundColor: "primary.main",
                  color: "secondary.main",
                }
          }
        >
          {categoryMainFriendlyName}
        </Typography>
        {mainFriendlyNameToCategoryMap
          .get(categoryMainFriendlyName)
          .sort((a, b) => {
            if (a.subFriendlyName < b.subFriendlyName) return -1;
            if (a.subFriendlyName > b.subFriendlyName) return 1;
            return 0;
          })
          .map((category) => {
            return (
              <CategoryChip
                key={category.subFriendlyName}
                name={category.subFriendlyName}
                category={category}
                isDeleteAvailable={true}
                color="secondary"
                sx={{
                  borderRadius: "5px",
                  margin: "5px",
                  height: "50px",
                  ":hover": { color: "secondary.main", "& .MuiChip-deleteIcon": { color: "secondary.main" } },
                }}
              />
            );
          })}
        <AddSubCategoryButton mainFriendlyName={categoryMainFriendlyName} />
      </Box>
    );
  };

  const renderCategoryList = () => {
    if (mainFriendlyNameToCategoryMap) {
      return Array.from(mainFriendlyNameToCategoryMap.keys())
        .sort()
        .map((categoryMainFriendlyName) => {
          if (categoryMainFriendlyName !== UNCATEGORIZED) {
            return renderMainCategory(categoryMainFriendlyName);
          }
          return "";
        });
    } else {
      if (categoryError !== "") {
        return <Alert severity="error">Error pulling categories</Alert>;
      }
      return <LinearProgress />;
    }
  };

  const renderAreYouSureYouWantToResetToDefaultDialog = () => {
    return (
      <Dialog
        open={isAreYouSureYouWantToResetToDefaultDialogOpen}
        onClose={(event, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
          }
          setIsAreYouSureYouWantToResetToDefaultDialogOpen(false);
        }}
      >
        <DialogTitle>Are you sure you want to continue?</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            You will lose <strong>all</strong> custom category changes you have made and <strong>each</strong>{" "}
            transaction's category will be reset to the default.
            <br />
            This action <strong>cannot be reversed</strong>.
          </Typography>
          {isResetToDefaultError && <Alert severity="error">Unexpected error</Alert>}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setIsAreYouSureYouWantToResetToDefaultDialogOpen(false);
              setIsResetToDefaultError(false);
            }}
            size="large"
            disabled={isResetToDefaultLoading}
          >
            No
          </Button>
          <Button variant="contained" color="warning" onClick={handleResetToDefaultClicked} size="large">
            {isResetToDefaultLoading ? <CircularProgress size={25} sx={{ color: "secondary.main" }} /> : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Dialog
      open={props.isDialogOpen}
      onClose={props.handleDialogClosed}
      fullScreen={!props.isDesktopScreenSize}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle>
        Category Settings{" "}
        <IconButton onClick={props.handleDialogClosed} sx={{ float: "right" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isResetToDefaultSuccess && (
          <Alert
            severity="success"
            variant="filled"
            onClose={() => setIsResetToDefaultSuccess(false)}
            sx={{ marginBottom: "5px" }}
          >
            Your categories have been reset. We are cleaning up your transaction categories in the background. This will
            take some time.
          </Alert>
        )}
        <AddMainCategoryButton isDesktopScreenSize={props.isDesktopScreenSize} />
        <Chip
          key="reset-to-default"
          label="RESET TO DEFAULT"
          onClick={() => {
            setIsAreYouSureYouWantToResetToDefaultDialogOpen(true);
            setIsResetToDefaultSuccess(false);
          }}
          variant="contained"
          color="warning"
          icon={<RestartAltIcon />}
          disabled={!mainFriendlyNameToCategoryMap}
          sx={
            props.isDesktopScreenSize
              ? {
                  borderRadius: "5px",
                  margin: "5px",
                  height: "50px",
                }
              : { borderRadius: "5px", height: "50px", width: "100%", marginTop: "5px" }
          }
        />
        <Box
          sx={{
            marginTop: "10px",
            height: "400px",
            maxHeight: "90vh",
            overflowY: "scroll",
            "::-webkit-scrollbar": { WebkitAppearance: "none", width: "7px" },
            "::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              backgroundColor: "#115247",
              WebkitBoxShadow: "0 0 1px rgba(255, 255, 255, .5)",
            },
          }}
        >
          {renderCategoryList()}
        </Box>
      </DialogContent>
      {isAreYouSureYouWantToResetToDefaultDialogOpen && renderAreYouSureYouWantToResetToDefaultDialog()}
    </Dialog>
  );
};

CategorySettingsDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  handleDialogClosed: PropTypes.func.isRequired,
  isDesktopScreenSize: PropTypes.bool.isRequired,
};

export default CategorySettingsDialog;
