export const ACCOUNT_GROUPINGS = Object.freeze({
  CASH: ["depository"],
  CREDIT: ["credit"],
  LOANS: ["loan"],
  INVESTMENTS: ["investment"],
});

export const NET_WORTH_DATE_FILTER = Object.freeze({
  "7D": "7D",
  "30D": "30D",
  "3M": "3M",
  "6M": "6M",
  "1Y": "1Y",
  "5Y": "5Y",
});

export const SEARCH_TRANSACTIONS_DATE_RANGE_FILTER = Object.freeze({
  "7D": "7D",
  "30D": "30D",
  "3M": "3M",
  "6M": "6M",
  "1Y": "1Y",
  "5Y": "5Y",
  CUSTOM: "CUSTOM",
});

export const UNCATEGORIZED = "Uncategorized";

// Websocket
export const WEBSOCKET_CLOSE_REASON_LOGGING_OUT = "Logging Out";
export const WS_CLIENT_NORMAL_CLOSURE_CODE = 1000;

export const APP_AUTHENTICATED_PATHS = Object.freeze({
  HOME: "/home",
  TRENDS: "/trends",
});

export const APP_PATHS = Object.freeze({
  ROOT: "/",
  ABOUT: "/about",
  SIGN_UP: "/signUp",
  CONFIRM_SIGN_UP: "/confirmSignUp",
  FORGOT_PASSWORD: "/forgotPassword",
  CONFIRM_FORGOT_PASSWORD: "/confirmForgotPassword",
  LOG_IN: "/logIn",
  ...APP_AUTHENTICATED_PATHS,
});
