import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { addCustomAsset, deleteCustomAsset, updateCustomAsset } from "../api/budget";
import { useAuth } from "./auth/AuthProvider";

const CustomAssetDialog = (props) => {
  const { accessToken, userId } = useAuth();
  const [name, setName] = useState("");
  const [balance, setBalance] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    props.name && setName(props.name);
    props.balance && setBalance(props.balance);
  }, [props.name, props.balance]);

  const handleCloseAddCustomAssetDialog = () => {
    setName("");
    setBalance("");
    setErrorMessage("");
    props.setIsDialogOpen(false);
  };

  const handleAddCustomAsset = () => {
    setIsLoading(true);
    addCustomAsset(userId, name, parseFloat(balance), accessToken)
      .then((response) => {
        if (response.pk) {
          setIsLoading(false);
          handleCloseAddCustomAssetDialog();
          props.onUpdateCustomAsset(userId, accessToken);
        } else {
          throw Error("Unexpected error");
        }
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
        setErrorMessage("Unexpected error");
      });
  };

  const handleUpdateCustomAsset = () => {
    setIsLoading(true);
    updateCustomAsset(userId, props.id, JSON.stringify({ name: name, balance: parseFloat(balance) }), accessToken)
      .then((response) => {
        if (response.pk) {
          setIsLoading(false);
          handleCloseAddCustomAssetDialog();
          props.onUpdateCustomAsset(userId, accessToken);
        } else {
          throw Error("Unexpected error");
        }
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
        setErrorMessage("Unexpected error");
      });
  };

  const handleDeleteClicked = () => {
    setIsDeleteLoading(true);
    deleteCustomAsset(userId, props.id, accessToken).then(() => {
      handleCloseAddCustomAssetDialog();
      props.onUpdateCustomAsset(userId, accessToken);
      setIsDeleteLoading(false);
    });
  };

  return (
    <Dialog
      open={props.isDialogOpen}
      onKeyUp={(e) => {
        const ENTER = 13;

        if (e.keyCode === ENTER) {
          props.isEdit ? handleUpdateCustomAsset() : handleAddCustomAsset();
        }
      }}
      fullScreen={!props.isDesktopScreenSize}
    >
      <DialogTitle>{props.isEdit ? "Edit Custom Asset" : "Add Custom Asset"}</DialogTitle>
      <DialogContent>
        <Box m={2}>
          <TextField
            variant="outlined"
            label="Name"
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
          ></TextField>
        </Box>
        <Box m={2}>
          <FormControl fullWidth>
            <InputLabel htmlFor="balance-input">Balance</InputLabel>
            <OutlinedInput
              id="balance-input"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label="Balance"
              value={balance}
              onChange={(event) => setBalance(event.target.value)}
            />
          </FormControl>
        </Box>
        {errorMessage !== "" && <Alert severity="error">{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions sx={!props.isDesktopScreenSize && { marginBottom: "1rem" }}>
        <Button
          variant="contained"
          color="error"
          size="large"
          onClick={handleDeleteClicked}
          disabled={isLoading || isDeleteLoading}
        >
          {isDeleteLoading ? <CircularProgress size={26} /> : "Delete"}
        </Button>
        <Button
          variant="outlined"
          onClick={handleCloseAddCustomAssetDialog}
          size="large"
          disabled={isLoading || isDeleteLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={props.isEdit ? handleUpdateCustomAsset : handleAddCustomAsset}
          size="large"
          disabled={isLoading || isDeleteLoading}
        >
          {isLoading ? <CircularProgress size={26} /> : props.isEdit ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CustomAssetDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  isDesktopScreenSize: PropTypes.bool.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  name: PropTypes.string,
  balance: PropTypes.number,
  onUpdateCustomAsset: PropTypes.func.isRequired,
};

export default CustomAssetDialog;
