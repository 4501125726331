export const sortTransactions = (transactions) => {
  // Convert object to an array of key-value pairs
  const transactionsArray = Object.entries(transactions);

  // Sort the array based on the "pending" property
  transactionsArray.sort((a, b) => {
    // Objects with pending=true should come first
    if (a[1].pending && !b[1].pending) {
      return -1;
    }
    // Objects with pending=false should come later
    if (!a[1].pending && b[1].pending) {
      return 1;
    }
    // Maintain the original order for objects with the same pending value
    return 0;
  });

  // Convert the sorted array back to an object if needed
  const sortedTransactions = Object.fromEntries(transactionsArray);
  return sortedTransactions;
};

export const sortTransactionsArray = (transactionsArray) => {
  // Sort the array based on the "pending" property
  transactionsArray.sort((a, b) => {
    // Objects with pending=true should come first
    if (a.pending && !b.pending) {
      return -1;
    }
    // Objects with pending=false should come later
    if (!a.pending && b.pending) {
      return 1;
    }
    // Maintain the original order for objects with the same pending value
    return 0;
  });

  return transactionsArray;
};
