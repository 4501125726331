import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import finwatch_logo from "../assets/finwatch_app_icon_white_border.png";
import { useHistory } from "react-router-dom";
import { NavLink as RouterNavLink } from "react-router-dom";
import { APP_PATHS } from "../utils/constants";

const Hero = () => {
  const history = useHistory();

  const logInClicked = () => {
    history.push(APP_PATHS.LOG_IN, {});
  };

  return (
    <Grid
      container
      sx={{
        margin: "auto",
        maxWidth: "90vw",
        width: "300px",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "secondary.main",
      }}
    >
      <Grid item xs={12}>
        <Box
          component="img"
          sx={{
            height: "250px",
            display: "block",
            margin: "0 auto",
          }}
          alt="Logo"
          src={finwatch_logo}
        />
        <Typography variant="h3" sx={{ fontFamily: "Noto Serif Balinese" }} textAlign="center">
          finwatch
        </Typography>
        <Typography variant="body1" textAlign="center">
          Simply review your finances.
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => logInClicked()}
          size="large"
          sx={{ display: "flex", margin: "auto", marginTop: "20px", height: "50px", backgroundColor: "primary.dark" }}
        >
          Log In
        </Button>
        <RouterNavLink to="/about" style={{ textDecoration: "none", color: "inherit" }}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            sx={{ display: "flex", margin: "auto", marginTop: "20px", height: "50px" }}
          >
            How Does It Work?
          </Button>
        </RouterNavLink>
      </Grid>
    </Grid>
  );
};

export default Hero;
