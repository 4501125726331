import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Chip, CircularProgress, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { addCategory } from "../../api/category";
import { useAuth } from "../auth/AuthProvider";
import { useCategory } from "./CategoryProvider";

const AddMainCategoryButton = (props) => {
  const { accessToken, userId } = useAuth();

  const { mainFriendlyNameToCategoryMap, fetchCategoriesByUser } = useCategory();
  const [isNewMainCategoryInputActive, setIsNewMainCategoryInputActive] = useState(false);
  const [newMainCategory, setNewMainCategory] = useState("");
  const [newSubCategory, setNewSubCategory] = useState("");
  const [isAddLoading, setIsAddLoading] = useState(false);

  const closeForm = () => {
    setIsAddLoading(false);
    setIsNewMainCategoryInputActive(false);
    setNewMainCategory("");
    setNewSubCategory("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleMainCategoryCreate();
    }
  };

  const handleMainCategoryCreate = () => {
    setIsAddLoading(true);
    addCategory(userId, newMainCategory, newSubCategory, accessToken).then(() => {
      fetchCategoriesByUser();
      closeForm();
    });
  };

  const handleNewMainCategoryClicked = () => {
    if (!isNewMainCategoryInputActive) {
      setIsNewMainCategoryInputActive(true);
    }
  };

  if (isNewMainCategoryInputActive) {
    return (
      <Grid container sx={{ border: "solid 3px", borderColor: "primary.main", padding: "20px", borderRadius: "5px" }}>
        <Grid item xs={12} md={4.5} sx={{ padding: "5px" }}>
          <TextField
            placeholder="New Main Category"
            variant="outlined"
            value={newMainCategory}
            onChange={(event) => setNewMainCategory(event.target.value)}
            onKeyDown={handleKeyDown}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4.5} sx={{ padding: "5px" }}>
          <TextField
            placeholder="New Sub Category"
            variant="outlined"
            value={newSubCategory}
            onChange={(event) => setNewSubCategory(event.target.value)}
            onKeyDown={handleKeyDown}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ textAlign: "right", marginTop: "10px" }}>
          <Button onClick={closeForm} variant="outlined" size="large" disabled={isAddLoading}>
            <CloseIcon />
          </Button>
          <Button onClick={handleMainCategoryCreate} variant="contained" size="large" sx={{ marginLeft: "10px" }}>
            {isAddLoading ? <CircularProgress size={24} sx={{ color: "secondary.main" }} /> : <CheckIcon />}
          </Button>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Chip
        key="new-main-category"
        label="NEW MAIN CATEGORY"
        onClick={handleNewMainCategoryClicked}
        variant="outlined"
        color="primary"
        icon={<AddIcon />}
        disabled={!mainFriendlyNameToCategoryMap}
        sx={
          props.isDesktopScreenSize
            ? {
                borderRadius: "5px",
                margin: "5px",
                height: "50px",
              }
            : { borderRadius: "5px", height: "50px", width: "100%" }
        }
      />
    );
  }
};

AddMainCategoryButton.propTypes = {
  isDesktopScreenSize: PropTypes.bool.isRequired,
};

export default AddMainCategoryButton;
