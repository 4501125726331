export const getUserConfigByUserId = async (userId, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/getUserConfigByUserId/${encodeURIComponent(userId)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const getItemsByUser = async (userId, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/getItemsByUser/${encodeURIComponent(userId)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const getCategoriesByUser = async (userId, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/getCategoriesByUser/${encodeURIComponent(userId)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const getCustomAssetsByUser = async (userId, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/getCustomAssetsByUser/${encodeURIComponent(userId)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const getTransactionsByUser = async (userId, token, LastEvaluatedKey = null) => {
  let apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/getTransactionsByUser/${encodeURIComponent(userId)}`;
  if (LastEvaluatedKey) {
    apiEndpoint += "/" + encodeURIComponent(JSON.stringify(LastEvaluatedKey));
  }
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const getTransactionsByUserByDate = async (userId, token, date) => {
  let apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/getTransactionsByUserByDate/${encodeURIComponent(
    userId
  )}/${encodeURIComponent(date)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const searchTransactionsByUserByDateRange = async (
  userId,
  startDate,
  endDate,
  searchString,
  token,
  LastEvaluatedKey = null
) => {
  let apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/searchTransactionsByUserByDateRange/${encodeURIComponent(
    userId
  )}/${encodeURIComponent(startDate)}/${encodeURIComponent(endDate)}/${encodeURIComponent(searchString)}`;
  if (LastEvaluatedKey) {
    apiEndpoint += "/" + encodeURIComponent(JSON.stringify(LastEvaluatedKey));
  }
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const addItem = async (userId, publicToken, institutionId, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/addItem/${encodeURIComponent(
    userId
  )}/${publicToken}/${institutionId}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    method: "POST",
  });
  if (!response.ok && response.status !== 400 && response.status !== 409) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`POST ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const addCustomAsset = async (userId, name, balance, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/addCustomAsset/${encodeURIComponent(
    userId
  )}/${name}/${balance}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    method: "POST",
  });
  if (!response.ok && response.status !== 400 && response.status !== 409) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`POST ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const updateCustomAsset = async (userId, customAssetId, items, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/updateCustomAsset/${encodeURIComponent(
    userId
  )}/${customAssetId}/${encodeURIComponent(items)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    method: "POST",
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`POST ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const deleteCustomAsset = async (userId, customAssetId, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/deleteCustomAsset/${encodeURIComponent(
    userId
  )}/${customAssetId}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    method: "POST",
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`POST ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const readTransaction = async (userId, transactionId, isRead, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/readTransaction/${encodeURIComponent(
    userId
  )}/${transactionId}/${isRead}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    method: "POST",
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`POST ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const updateTransaction = async (userId, transactionId, items, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/updateTransaction/${encodeURIComponent(
    userId
  )}/${transactionId}/${encodeURIComponent(items)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    method: "POST",
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`POST ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const toggleUserPrivacyFilter = async (userId, isPrivacyFilterOn, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/toggleUserPrivacyFilter/${encodeURIComponent(
    userId
  )}/${isPrivacyFilterOn}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    method: "POST",
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`POST ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const syncTransactions = async (userId, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/syncTransactions/${encodeURIComponent(userId)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const syncUserData = async (userId, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/syncUserData/${encodeURIComponent(userId)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const syncAccountBalance = async (userId, itemSk, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/syncAccountBalance/${encodeURIComponent(
    userId
  )}/${encodeURIComponent(itemSk)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const removeItem = async (userId, itemSk, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/removeItem/${encodeURIComponent(
    userId
  )}/${encodeURIComponent(itemSk)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
    method: "POST",
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`POST ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const getNetWorthByUserByDateRange = async (userId, dateStart, dateEnd, token) => {
  let apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/getNetWorthByUserByDateRange/${encodeURIComponent(
    userId
  )}/${encodeURIComponent(dateStart)}/${encodeURIComponent(dateEnd)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};
