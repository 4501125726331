import React from "react";
import { Route, Switch } from "react-router-dom";

import About from "./views/About";
import Home from "./views/Home";
import Trends from "./views/Trends";

import { Box, useMediaQuery } from "@mui/material";
import { CategoryProvider } from "./components/category/CategoryProvider";
import Hero from "./components/Hero";
import NavBar from "./components/NavBar";
import { WebSocketProvider } from "./components/websocket/WebSocketProvider";
import ConfirmSignUp from "./views/auth/ConfirmSignUp";
import LogIn from "./views/auth/LogIn";
import SignUp from "./views/auth/SignUp";
import ForgotPassword from "./views/auth/ForgotPassword";
import ConfirmForgotPassword from "./views/auth/ConfirmForgotPassword";
import { APP_PATHS } from "./utils/constants";

const App = () => {
  const isDesktopScreenSize = useMediaQuery("(min-width:900px");
  return (
    <WebSocketProvider>
      <CategoryProvider>
        <Box
          sx={{
            backgroundColor: "primary.main",
            height: "100%",
            minHeight: `calc(100% + env(safe-area-inset-top))`,
            padding: `env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)`,
          }}
        >
          <Switch>
            <Route path={APP_PATHS.ROOT} exact render={() => <Hero />} />
            <Route path={APP_PATHS.ABOUT} render={() => <About isDesktopScreenSize={isDesktopScreenSize} />} />
            <Route path={APP_PATHS.SIGN_UP} render={() => <SignUp isDesktopScreenSize={isDesktopScreenSize} />} />
            <Route
              path={APP_PATHS.CONFIRM_SIGN_UP}
              render={() => <ConfirmSignUp isDesktopScreenSize={isDesktopScreenSize} />}
            />
            <Route
              path={APP_PATHS.FORGOT_PASSWORD}
              render={() => <ForgotPassword isDesktopScreenSize={isDesktopScreenSize} />}
            />
            <Route
              path={APP_PATHS.CONFIRM_FORGOT_PASSWORD}
              render={() => <ConfirmForgotPassword isDesktopScreenSize={isDesktopScreenSize} />}
            />
            <Route path={APP_PATHS.LOG_IN} render={() => <LogIn isDesktopScreenSize={isDesktopScreenSize} />} />
            <Route
              path={APP_PATHS.HOME}
              render={() => (
                <NavBar isDesktopScreenSize={isDesktopScreenSize}>
                  <Home isDesktopScreenSize={isDesktopScreenSize} />
                </NavBar>
              )}
            />
            <Route
              path={APP_PATHS.TRENDS}
              render={() => (
                <NavBar isDesktopScreenSize={isDesktopScreenSize}>
                  <Trends isDesktopScreenSize={isDesktopScreenSize} />
                </NavBar>
              )}
            />
          </Switch>
        </Box>
      </CategoryProvider>
    </WebSocketProvider>
  );
};

export default App;
