import { Alert, Box, Button, CircularProgress, Divider, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { signUp } from "../../api/auth/cognito";
import finwatch_logo from "../../assets/finwatch_app_icon_white_border.png";
import PasswordInput from "../../components/PasswordInput";
import { APP_PATHS } from "../../utils/constants";

export const SignUp = (props) => {
  const history = useHistory();
  // Page State
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "", phoneNumber: "" });
  const [signUpSubmissionError, setSignUpSubmissionError] = useState("");
  const [isSignUpSubmissionLoading, setIsSignUpSubmissionLoading] = useState(false);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = re.test(String(email).toLowerCase());
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: isValid ? "" : "Invalid email address.",
    }));
    return isValid;
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    const isValid = re.test(password);
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: isValid
        ? ""
        : "Password must be at least 6 characters, and include at least one uppercase letter, one lowercase letter, one number, and one special character.",
    }));
    return isValid;
  };

  const validatePhoneNumber = (phoneNumber) => {
    const re = /^\+[1-9]\d{1,14}$/;
    const isValid = re.test(phoneNumber);
    setErrors((prevErrors) => ({
      ...prevErrors,
      phoneNumber: isValid ? "" : "Phone number must be in E.164 format (e.g., +1234567890).",
    }));
    return isValid;
  };

  const handleSignUpClicked = (event) => {
    event.preventDefault();

    const isEmailValid = validateEmail(email);
    const isPasswordValid = validatePassword(password);
    const isPhoneNumberValid = validatePhoneNumber(phoneNumber);

    if (isEmailValid && isPasswordValid && isPhoneNumberValid) {
      // Perform sign-up operation
      setIsSignUpSubmissionLoading(true);
      console.log("Sign-up data is valid. Proceeding with sign-up...");
      signUp({ email: email, password: password, phone_number: phoneNumber }).then((response) => {
        console.log("response: ", response);
        if (response.error) {
          setSignUpSubmissionError(response.error);
          setIsSignUpSubmissionLoading(false);
        } else {
          console.log("Success!");
          setIsSignUpSubmissionLoading(false);
          history.push(APP_PATHS.CONFIRM_SIGN_UP, { email: email });
        }
      });
    }
  };

  const handleGoogleClicked = () => {
    // TODO: Complete handleGoogleClicked...
  };

  const handleBackClicked = () => {
    history.goBack();
  };

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Stack
        direction="column"
        sx={{
          margin: "auto",
          height: "100vh",
          width: "300px",
          maxWidth: "90vw",
          color: "secondary.main",
        }}
      >
        <Box
          component="img"
          sx={{
            margin: "10px auto",
            width: props.isDesktopScreenSize ? "130px" : "75px",
          }}
          alt="Logo"
          src={finwatch_logo}
        />
        <Typography variant="h3" sx={{ fontFamily: "Noto Serif Balinese" }} textAlign="center">
          finwatch
        </Typography>
        <form onSubmit={handleSignUpClicked}>
          <TextField
            name="email"
            label="Email"
            fullWidth
            variant="filled"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onBlur={() => validateEmail(email)}
            error={!!errors.email}
            helperText={errors.email}
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
              backgroundColor: "secondary.main",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          />
          <PasswordInput
            password={password}
            setPassword={setPassword}
            error={errors.password}
            validatePassword={validatePassword}
          />
          <TextField
            name="phoneNumber"
            label="Phone Number"
            fullWidth
            variant="filled"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            onBlur={() => validatePhoneNumber(phoneNumber)}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
              backgroundColor: "secondary.main",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          />
          {signUpSubmissionError !== "" && (
            <Alert severity="error" variant="filled" sx={{ marginTop: "10px", marginBottom: "20px" }}>
              {signUpSubmissionError}
            </Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            sx={{
              display: "flex",
              margin: "auto",
              marginTop: "10px",
              height: "50px",
              backgroundColor: "primary.dark",
            }}
            disabled={isSignUpSubmissionLoading}
          >
            {isSignUpSubmissionLoading ? <CircularProgress /> : "Sign Up"}
          </Button>
          <Divider sx={{ marginTop: "25px", backgroundColor: "secondary.main" }} />
          <Typography sx={{ margin: "10px", textAlign: "center" }}>OR</Typography>
          <Divider sx={{ marginBottom: "25px", backgroundColor: "secondary.main" }} />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleGoogleClicked}
            disabled
            sx={{
              display: "flex",
              margin: "auto",
              marginBottom: "10px",
              height: "50px",
              backgroundColor: "primary.dark",
            }}
          >
            Google (TBD)
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={handleBackClicked}
            sx={{
              color: "primary.main",
              backgroundColor: "secondary.main",
              ":hover": {
                color: "secondary.main",
                backgroundColor: "secondary.dark",
              },
              display: "flex",
              margin: "auto",
              marginTop: "30px",
              marginBottom: "30px",
              height: "50px",
            }}
          >
            Back
          </Button>
        </form>
      </Stack>
    </Box>
  );
};

SignUp.propTypes = {
  isDesktopScreenSize: PropTypes.bool.isRequired,
};

export default SignUp;
