import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      dark: "#012420",
      main: "#115247",
      light: "#057E65",
    },
    secondary: {
      dark: "#444140",
      main: "#f5f5f5",
      light: "#ffffff",
      grey: "#c4c3c2",
    },
    money: {
      positive: "#81c784",
      // positive: "#1b5e20",
      // positive: "#4caf50",
      // positive: "#8bc34a",
      // positive: "#c8e6c9",
      negative: "#e57373",
      // negative: "#b71c1c",
      // negative: "#f44336",
      // negative: "#ffcdd2",
    },
  },
});

export default theme;
