import { Avatar, Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import { formatIntToMoney } from "../utils/customFormats";
import SettingsIcon from "@mui/icons-material/Settings";
import PropTypes from "prop-types";
import { useState } from "react";
import CustomAssetDialog from "./CustomAssetDialog";

const CustomAsset = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSettingsButtonClicked = () => {
    setIsDialogOpen(true);
  };

  return (
    <Paper
      elevation={1}
      sx={{
        margin: "5px",
        padding: "10px",
        color: "secondary.main",
        backgroundColor: "primary.main",
        borderRadius: "10px",
      }}
    >
      <Stack direction="row">
        <Avatar
          alt="account-logo"
          sx={{
            height: props.isDesktopScreenSize ? "50px" : "25px",
            width: props.isDesktopScreenSize ? "50px" : "25px",
            border: props.isDesktopScreenSize ? "5px solid white" : "2px solid white",
            marginRight: "10px",
          }}
        />
        <Box>
          <Typography variant={props.isDesktopScreenSize ? "body1" : "body2"}>{props.name}</Typography>
          <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
            {!props.isPrivacyFilterOn ? formatIntToMoney(props.balance) : "**********"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
          <IconButton id="account-settings-button" onClick={handleSettingsButtonClicked} color="secondary">
            <SettingsIcon />
          </IconButton>
        </Box>
      </Stack>
      {isDialogOpen && (
        <CustomAssetDialog
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          isEdit={true}
          id={props.id}
          name={props.name}
          balance={props.balance}
          onUpdateCustomAsset={props.onUpdateCustomAsset}
          isDesktopScreenSize={props.isDesktopScreenSize}
        />
      )}
    </Paper>
  );
};

CustomAsset.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
  isDesktopScreenSize: PropTypes.bool.isRequired,
  isPrivacyFilterOn: PropTypes.bool.isRequired,
  onUpdateCustomAsset: PropTypes.func.isRequired,
};

export default CustomAsset;
