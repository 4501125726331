import {
  CognitoIdentityProviderClient,
  ConfirmForgotPasswordCommand,
  ConfirmSignUpCommand,
  ForgotPasswordCommand,
  InitiateAuthCommand,
  ResendConfirmationCodeCommand,
  SignUpCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { setTokenCookies } from "./auth";

const cognitoClient = new CognitoIdentityProviderClient({ region: "us-east-1" });

export const signUp = async (payload) => {
  const params = {
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    Username: payload.email,
    Password: payload.password,
    UserAttributes: [
      { Name: "email", Value: payload.email },
      { Name: "phone_number", Value: payload.phone_number },
    ],
  };

  try {
    const command = new SignUpCommand(params);
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
};

export const confirmSignUp = async (payload) => {
  const params = {
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    Username: payload.email,
    ConfirmationCode: payload.code,
  };

  try {
    const command = new ConfirmSignUpCommand(params);
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error(error);
    console.error(error.message);
    return { error: error.message };
  }
};

export const resendConfirmationCode = async (payload) => {
  const params = {
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    Username: payload.email,
  };

  try {
    const command = new ResendConfirmationCodeCommand(params);
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
};

export const logIn = async (payload) => {
  const params = {
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    AuthParameters: {
      USERNAME: payload.email,
      PASSWORD: payload.password,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const response = await cognitoClient.send(command);
    if (response.AuthenticationResult) {
      const tokenResponse = await setTokenCookies({
        accessToken: response.AuthenticationResult.AccessToken,
        idToken: response.AuthenticationResult.IdToken,
        refreshToken: response.AuthenticationResult.RefreshToken,
      });
      return tokenResponse;
    }
    throw Error("Unexpected Error");
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const refreshTokens = async (payload) => {
  const params = {
    AuthFlow: "ALLOW_REFRESH_TOKEN_AUTH",
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    AuthParameters: {
      REFRESH_TOKEN: payload.refreshToken,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const response = await cognitoClient.send(command);
    if (response.AuthenticationResult) {
      const tokenResponse = await setTokenCookies({
        accessToken: response.AuthenticationResult.AccessToken,
        idToken: response.AuthenticationResult.IdToken,
        refreshToken: response.AuthenticationResult.RefreshToken,
      });
      return tokenResponse;
    }
    throw Error("Unexpected Error");
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const forgotPassword = async (payload) => {
  const params = {
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    Username: payload.email,
  };

  try {
    const command = new ForgotPasswordCommand(params);
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
};

export const confirmForgotPassword = async (payload) => {
  const params = {
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    Username: payload.email,
    ConfirmationCode: payload.confirmationCode,
    Password: payload.newPassword,
  };

  try {
    const command = new ConfirmForgotPasswordCommand(params);
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error(error);
    return { error: error.message };
  }
};
