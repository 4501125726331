import { Alert, Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { confirmForgotPassword } from "../../api/auth/cognito";
import finwatch_logo from "../../assets/finwatch_app_icon_white_border.png";
import PasswordInput from "../../components/PasswordInput";
import { APP_PATHS } from "../../utils/constants";

export const ConfirmForgotPassword = (props) => {
  const location = useLocation();
  const history = useHistory();
  // Page State
  const [email, setEmail] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newRetypePassword, setNewRetypePassword] = useState("");
  const [errors, setErrors] = useState({ confirmationCode: "", newPassword: "", newRetypePassword: "" });
  const [submitError, setSubmitError] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
    } else {
      history.push(APP_PATHS.LOG_IN, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.email]);

  const validateCode = (code) => {
    const regex = /^\d{6}$/;
    const isValid = regex.test(code);
    setErrors((prevErrors) => ({
      ...prevErrors,
      confirmationCode: isValid ? "" : "Invalid confirmation code.",
    }));
    return isValid;
  };

  const validatePassword = (password) => {
    let validationError = "";

    const isSameAsRetypePassword = password === newRetypePassword;
    validationError = isSameAsRetypePassword ? "" : "Passwords must match.";
    setErrors((prevErrors) => ({
      ...prevErrors,
      newPassword: isSameAsRetypePassword ? "" : "Passwords must match.",
      newRetypePassword: isSameAsRetypePassword ? "" : "Passwords must match.",
    }));

    if (validationError === "") {
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
      const isValid = re.test(password);
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: isValid
          ? ""
          : "Password must be at least 6 characters, and include at least one uppercase letter, one lowercase letter, one number, and one special character.",
      }));
    }

    return validationError === "";
  };

  const validateRetypePassword = (password) => {
    let validationError = "";

    const isSameAsPassword = password === newPassword;
    validationError = isSameAsPassword ? "" : "Passwords must match.";
    setErrors((prevErrors) => ({
      ...prevErrors,
      newPassword: isSameAsPassword ? "" : "Passwords must match.",
      newRetypePassword: isSameAsPassword ? "" : "Passwords must match.",
    }));

    if (validationError === "") {
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
      const isValid = re.test(password);
      setErrors((prevErrors) => ({
        ...prevErrors,
        newRetypePassword: isValid
          ? ""
          : "Password must be at least 6 characters, and include at least one uppercase letter, one lowercase letter, one number, and one special character.",
      }));
    }

    return validationError === "";
  };

  const handleSubmitClicked = (event) => {
    event.preventDefault();
    setIsSubmitLoading(true);
    if ((validateCode(email), validatePassword(newPassword), validateRetypePassword(newRetypePassword))) {
      confirmForgotPassword({
        email: email,
        confirmationCode: confirmationCode,
        newPassword: newPassword,
      })
        .then((response) => {
          if (response.error) {
            throw Error(response.error);
          }
          history.push(APP_PATHS.LOG_IN, {
            email: email,
            message: "Your password was reset. You may now log in with it.",
          });
        })
        .catch((e) => {
          setSubmitError(e.message);
        })
        .finally(() => {
          setIsSubmitLoading(false);
        });
    }
  };

  const handleBackClicked = () => {
    history.goBack();
  };

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Stack
        direction="column"
        sx={{
          margin: "auto",
          justifyContent: "center",
          height: "100vh",
          width: "300px",
          maxWidth: "90vw",
          color: "secondary.main",
        }}
      >
        <Box
          component="img"
          sx={{
            margin: "10px auto",
            width: props.isDesktopScreenSize ? "130px" : "75px",
          }}
          alt="Logo"
          src={finwatch_logo}
        />
        <Typography variant="h3" sx={{ fontFamily: "Noto Serif Balinese" }} textAlign="center">
          finwatch
        </Typography>
        <Alert
          severity="success"
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "secondary.main",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          We sent you a code to your email. <br />
          Please use it below to reset your password.
        </Alert>
        <form onSubmit={handleSubmitClicked}>
          <TextField
            label="Confirmation Code"
            fullWidth
            variant="filled"
            value={confirmationCode}
            onChange={(event) => setConfirmationCode(event.target.value)}
            onBlur={() => validateCode(confirmationCode)}
            error={!!errors.confirmationCode}
            helperText={errors.confirmationCode}
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
              backgroundColor: "secondary.main",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          />
          <PasswordInput
            label="New Password"
            password={newPassword}
            setPassword={setNewPassword}
            error={errors.newPassword}
            validatePassword={validatePassword}
          />
          <PasswordInput
            label="Retype New Password"
            password={newRetypePassword}
            setPassword={setNewRetypePassword}
            error={errors.newRetypePassword}
            validatePassword={validateRetypePassword}
          />
          {submitError !== "" && (
            <Alert severity="error" variant="filled" sx={{ marginTop: "10px", marginBottom: "20px" }}>
              {submitError}
            </Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            sx={{
              display: "flex",
              margin: "auto",
              marginTop: "10px",
              height: "50px",
              backgroundColor: "primary.dark",
            }}
            disabled={isSubmitLoading}
          >
            {isSubmitLoading ? <CircularProgress /> : "Submit"}
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={handleBackClicked}
            sx={{
              color: "primary.main",
              backgroundColor: "secondary.main",
              ":hover": {
                color: "secondary.main",
                backgroundColor: "secondary.dark",
              },
              display: "flex",
              margin: "auto",
              marginTop: "10px",
              marginBottom: "10px",
              height: "50px",
            }}
          >
            Back
          </Button>
        </form>
      </Stack>
    </Box>
  );
};

ConfirmForgotPassword.propTypes = {
  isDesktopScreenSize: PropTypes.bool.isRequired,
};

export default ConfirmForgotPassword;
