import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useState } from "react";
import { updateTransaction } from "../api/budget";
import { useAuth } from "./auth/AuthProvider";

const TransactionEditDialog = (props) => {
  const { accessToken, userId } = useAuth();

  const [name, setName] = useState(
    props.transaction?.merchant_name ? props.transaction?.merchant_name : props.transaction?.name
  );
  const [date, setDate] = useState(
    props.transaction.authorized_date ? props.transaction.authorized_date : props.transaction.date
  );

  const handleEditDialogSaveButtonClicked = () => {
    props.setName(name);
    props.setDate(date);
    props.handleEditDialogClosed();
    updateTransaction(
      userId,
      props.transaction.transaction_id,
      JSON.stringify({ merchant_name: name, authorized_date: date, gsi: date }),
      accessToken
    );
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.format("YYYY-MM-DD"));
  };

  const renderEditDialog = () => {
    return (
      <Dialog
        open={props.isEditDialogOpen}
        onClose={props.handleEditDialogClosed}
        fullWidth
        fullScreen={!props.isDesktopScreenSize}
      >
        <DialogTitle>Edit Transaction</DialogTitle>
        <DialogContent>
          <TextField
            name="name"
            label="Name"
            fullWidth
            variant="outlined"
            value={name}
            onChange={handleNameChange}
            sx={{ marginTop: "10px" }}
          />
          <MobileDatePicker
            label="Date"
            value={dayjs(date)}
            onChange={handleDateChange}
            closeOnSelect={true}
            orientation="portrait"
            sx={{ width: "100%", marginTop: "10px" }}
          />
        </DialogContent>
        <DialogActions sx={!props.isDesktopScreenSize && { marginBottom: "1rem" }}>
          <Button variant="outlined" size="large" onClick={props.handleEditDialogClosed} sx={{ width: "120px" }}>
            Cancel
          </Button>
          <Button variant="contained" size="large" onClick={handleEditDialogSaveButtonClicked} sx={{ width: "120px" }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return renderEditDialog();
};

TransactionEditDialog.propTypes = {
  transaction: PropTypes.object.isRequired,
  isEditDialogOpen: PropTypes.bool.isRequired,
  setIsEditDialogOpen: PropTypes.func.isRequired,
  handleEditDialogClosed: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
  isDesktopScreenSize: PropTypes.bool.isRequired,
};

export default TransactionEditDialog;
