import { Alert, Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { logIn, resendConfirmationCode } from "../../api/auth/cognito";
import finwatch_logo from "../../assets/finwatch_app_icon_white_border.png";
import PasswordInput from "../../components/PasswordInput";
import { useAuth } from "../../components/auth/AuthProvider";
import { APP_PATHS } from "../../utils/constants";

export const LogIn = (props) => {
  const { loadSession } = useAuth();

  const location = useLocation();
  const history = useHistory();
  // Page State
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [logInMessage, setLogInMessage] = useState("");
  const [logInError, setLogInError] = useState("");
  const [isLogInLoading, setIsLogInLoading] = useState(false);

  useEffect(() => {
    if (location.state.error) {
      setLogInError(location.state.error);
    }
  }, [location.state.error]);

  useEffect(() => {
    if (location.state.message) {
      setLogInMessage(location.state.message);
    }
  }, [location.state.message]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = re.test(String(email).toLowerCase());
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: isValid ? "" : "Invalid email address.",
    }));
    return isValid;
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    const isValid = re.test(password);
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: isValid
        ? ""
        : "Password must be at least 6 characters, and include at least one uppercase letter, one lowercase letter, one number, and one special character.",
    }));
    return isValid;
  };

  const handleForgotPasswordClicked = (event) => {
    history.push(APP_PATHS.FORGOT_PASSWORD, { email: email });
  };

  const handleLogInClicked = (event) => {
    event.preventDefault();
    setLogInError("");

    const isEmailValid = validateEmail(email);
    const isPasswordValid = validatePassword(password);

    if (isEmailValid && isPasswordValid) {
      setIsLogInLoading(true);
      logIn({ email: email, password: password })
        .then((response) => {
          if (response.error) {
            setLogInError(response.error);
            setIsLogInLoading(false);
          } else {
            loadSession().then(() => {
              setIsLogInLoading(false);
              history.push(APP_PATHS.HOME);
            });
          }
        })
        .catch((error) => {
          console.error(error.message);
          if (error.message === "Incorrect username or password.") {
            setLogInError(error.message);
          } else if (error.message === "User is not confirmed.") {
            resendConfirmationCode({ email: email }).then((response) => {
              if (response.error) {
                setLogInError(response.error);
                setIsLogInLoading(false);
              } else {
                setIsLogInLoading(false);
                history.push(APP_PATHS.CONFIRM_SIGN_UP, { email: email });
              }
            });
          } else {
            setLogInError("Unexpected Error occurred");
          }
          setIsLogInLoading(false);
        });
    }
  };

  const handleBackClicked = () => {
    history.push(APP_PATHS.ROOT);
  };

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Stack
        direction="column"
        sx={{
          margin: "auto",
          justifyContent: "center",
          height: "100vh",
          width: "300px",
          maxWidth: "90vw",
          color: "secondary.main",
        }}
      >
        <Box
          component="img"
          sx={{
            margin: "10px auto",
            width: props.isDesktopScreenSize ? "130px" : "75px",
          }}
          alt="Logo"
          src={finwatch_logo}
        />
        <Typography variant="h3" sx={{ fontFamily: "Noto Serif Balinese" }} textAlign="center">
          finwatch
        </Typography>
        {logInMessage !== "" && (
          <Alert severity="success" sx={{ marginTop: "10px", marginBottom: "20px" }}>
            {logInMessage}
          </Alert>
        )}
        {logInError !== "" && (
          <Alert severity="error" variant="filled" sx={{ marginTop: "10px", marginBottom: "20px" }}>
            {logInError}
          </Alert>
        )}
        <form onSubmit={handleLogInClicked}>
          <TextField
            name="email"
            label="Email"
            fullWidth
            variant="filled"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onBlur={() => validateEmail(email)}
            error={!!errors.email}
            helperText={errors.email}
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
              backgroundColor: "secondary.main",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          />
          <PasswordInput
            password={password}
            setPassword={setPassword}
            error={errors.password}
            validatePassword={validatePassword}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingBottom: "50px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleForgotPasswordClicked}
              color="secondary"
              size="small"
              sx={{
                marginTop: "10px",
                height: "30px",
              }}
              disabled={isLogInLoading}
            >
              Forgot Password?
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            sx={{
              display: "flex",
              margin: "auto",
              marginTop: "10px",
              height: "50px",
              backgroundColor: "primary.dark",
            }}
            disabled={isLogInLoading}
          >
            {isLogInLoading ? <CircularProgress /> : "Log In"}
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={handleBackClicked}
            sx={{
              color: "primary.main",
              backgroundColor: "secondary.main",
              ":hover": {
                color: "secondary.main",
                backgroundColor: "secondary.dark",
              },
              display: "flex",
              margin: "auto",
              marginTop: "10px",
              marginBottom: "10px",
              height: "50px",
            }}
          >
            Back
          </Button>
        </form>
      </Stack>
    </Box>
  );
};

LogIn.propTypes = {
  isDesktopScreenSize: PropTypes.bool.isRequired,
};

export default LogIn;
