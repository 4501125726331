export const getDateRangeGivenNetWorthInputString = (inputString) => {
  let dateStart = new Date();
  const dateEnd = new Date();
  switch (inputString) {
    case "7D":
      dateStart = new Date(dateStart.getTime() - 7 * 24 * 60 * 60 * 1000);
      break;
    case "30D":
      dateStart = new Date(dateStart.getTime() - 30 * 24 * 60 * 60 * 1000);
      break;
    case "3M":
      dateStart.setMonth(dateStart.getMonth() - 3);
      break;
    case "6M":
      dateStart.setMonth(dateStart.getMonth() - 6);
      break;
    case "1Y":
      dateStart.setFullYear(dateStart.getFullYear() - 1);
      break;
    case "5Y":
      dateStart.setFullYear(dateStart.getFullYear() - 5);
      break;
    default:
      console.error("Unrecognized inputString: ", inputString);
      break;
  }
  return {
    dateStart: dateStart.toISOString().substring(0, 10),
    dateEnd: dateEnd.toISOString().substring(0, 10),
  };
};

export const getMinYAxis = (netWorthData) => {
  const netWorthList = netWorthData.map((record) => record.netWorth);
  const minValue = Math.min(...netWorthList);
  const percent = minValue * 0.01;
  return minValue - percent;
};

export const getMaxYAxis = (netWorthData) => {
  const netWorthList = netWorthData.map((record) => record.netWorth);
  const maxValue = Math.max(...netWorthList);
  const percent = maxValue * 0.01;
  return maxValue + percent;
};
