export const setTokenCookies = async (payload) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/setTokenCookies`;
  const response = await fetch(apiEndpoint, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const getAccessToken = async () => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/getAccessToken`;
  const response = await fetch(apiEndpoint, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const logOut = async () => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/logOut`;
  const response = await fetch(apiEndpoint, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
  if (!response.ok && response.status !== 400) {
    if (response.status === 403) {
      return {
        statusCode: 403,
        message: "Unauthorized",
      };
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const getUserId = (accessToken) => {
  const decodedAccessToken = decodeJWT(accessToken);
  const userId = decodedAccessToken.payload.sub;
  return userId;
};

const base64UrlDecode = (base64Url) => {
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};

const decodeJWT = (token) => {
  const [header, payload, signature] = token.split(".");

  const decodedHeader = JSON.parse(base64UrlDecode(header));
  const decodedPayload = JSON.parse(base64UrlDecode(payload));

  return {
    header: decodedHeader,
    payload: decodedPayload,
    signature: signature,
  };
};
