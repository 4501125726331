export const getLinkToken = async (userId, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/getLinkToken/${encodeURIComponent(userId)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
  });
  if (!response.ok) {
    if (response.status === 400) {
      return response;
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};

export const getUpdateLinkToken = async (userId, itemSk, token) => {
  const apiEndpoint = `${process.env.REACT_APP_REST_ENDPOINT}/getUpdateLinkToken/${encodeURIComponent(
    userId
  )}/${encodeURIComponent(itemSk)}`;
  const response = await fetch(apiEndpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + token,
    },
  });
  if (!response.ok) {
    if (response.status === 400) {
      return response;
    }
    throw Error(`GET ${apiEndpoint} API Call failed.`);
  }
  const finalResponse = await response.json();
  return finalResponse;
};
