import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getCategoriesByUser } from "../../api/budget";
import { useAuth } from "../auth/AuthProvider";

const CategoryContext = createContext(null);

export const CategoryProvider = (props) => {
  const { accessToken, userId, isAuthenticated, clearSession } = useAuth();

  const [categories, setCategories] = useState(null);
  const [pkToCategoryMap, setPkToCategoryMap] = useState(null);
  const [mainFriendlyNameToCategoryMap, setMainFriendlyNameToCategoryMap] = useState(null);
  const [categoryError, setCategoryError] = useState([]);

  useEffect(() => {
    if (isAuthenticated()) {
      fetchCategoriesByUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchCategoriesByUser = () => {
    getCategoriesByUser(userId, accessToken)
      .then((response) => {
        if (response.statusCode === 403) {
          clearSession();
        } else {
          let newPkToCategoryMap = new Map();
          let newMainFriendlyNameToCategoryMap = new Map();
          for (const category of response.categories) {
            if (newMainFriendlyNameToCategoryMap.has(category.mainFriendlyName)) {
              newMainFriendlyNameToCategoryMap.get(category.mainFriendlyName).push(category);
            } else {
              newMainFriendlyNameToCategoryMap.set(category.mainFriendlyName, [category]);
            }

            newPkToCategoryMap.set(JSON.stringify({ sk: category.sk, pk: category.pk }), category);
          }
          setCategories(response.categories);
          setPkToCategoryMap(newPkToCategoryMap);
          setMainFriendlyNameToCategoryMap(newMainFriendlyNameToCategoryMap);
        }
      })
      .catch((e) => {
        setCategoryError("Unexpected Error.");
      });
  };

  const categoryProviderValue = useMemo(
    () => ({ fetchCategoriesByUser, categories, pkToCategoryMap, mainFriendlyNameToCategoryMap, categoryError }),
    [fetchCategoriesByUser, categories, pkToCategoryMap, mainFriendlyNameToCategoryMap, categoryError]
  );

  return <CategoryContext.Provider value={categoryProviderValue}>{props.children}</CategoryContext.Provider>;
};

CategoryProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export const useCategory = () => {
  return useContext(CategoryContext);
};
